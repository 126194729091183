<template>
  <table class="summary-table mx-auto">
    <thead>
      <tr>
        <th>Task</th>
        <th>For Employee</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="task in selected" :key="task.id">
        <td>{{ task.task_title }}</td>
        <td>{{ task.on_behalf_of.full_name }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["selected", "action"],
};
</script>
