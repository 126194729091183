import List from "./List.vue";
export default [
  {
    path: "/task-assignments",
    name: "taskAssignments",
    component: List,
    meta: {
      title: "Task Assignments",
    },
  },
];
