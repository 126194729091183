import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v("Total: "+_vm._s(_vm.meta.pagination.records))]},proxy:true},{key:"rightOfTitle",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.openModal({ mode: 'create' })}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add ")],1)]},proxy:true},{key:"right",fn:function(){return [_c('cp-filters-menu',{staticClass:"search--center",attrs:{"module":_vm.table}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.constants.TASKS)+" ")]),_c(VCard,{staticClass:"card-listing"},[_c(VDataTable,_vm._g(_vm._b({staticClass:"clickable-rows",attrs:{"hide-default-footer":""},on:{"click:row":function (item) { return _vm.openModal({ id: item.id }); }},scopedSlots:_vm._u([{key:"header.task_type_id",fn:function(ref){
var header = ref.header;
return [_c('cp-table-header-filterable',{attrs:{"header":header,"table":_vm.table,"filter-key":"task_types"}})]}},{key:"no-data",fn:function(){return [_c('no-data-screen',{attrs:{"text":_vm.noDataText,"noun":_vm.noun},on:{"click":function($event){return _vm.openModal({ mode: 'create' })}}})]},proxy:true},{key:"item.is_core",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('core-indicator',{staticClass:"ml-4 d-inline-block"},[_c('div',[_c('h3',[_vm._v("This is a core task.")]),_vm._v(" It enables core features. ")])]):_vm._e(),(
            !item.assignment_type ||
              (item.assignment_type === 'assignment_group' &&
                !item.assignment_group_id)
          )?_c('cp-explain',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"ml-3",attrs:{"color":"error"}},on),[_vm._v("mdi-alert")])]}}],null,true)},[_vm._v(" Missing Assignment! ")]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.toMDY(value))+" ")]}},{key:"item.task_type_id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.task_type.label)+" ")]}},{key:"item.alert",fn:function(ref){
          var item = ref.item;
return [_c('cp-explain',{attrs:{"close-on-content-click":false,"max-width":"550","offset-x":"","root-slot":""}},[_c('task-detail-card',{attrs:{"item":item}})],1)]}},{key:"item.tag_list",fn:function(ref){
          var value = ref.value;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"4px"}},_vm._l((value),function(tag,i){return _c(VChip,{key:i,attrs:{"pill":"","text":"","small":""}},[_vm._v(" "+_vm._s(tag)+" ")])}),1)]}}])},'v-data-table',_vm.tableProps,false),_vm.tableListeners)),(!_vm.noData)?_c(VPagination,_vm._g(_vm._b({staticClass:"mt-2 text-center"},'v-pagination',_vm.paginationProps,false),_vm.paginationListeners)):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }