<template>
  <div>
    <cp-h1>
      Mentors

      <template #subtitle> Total: {{ meta.pagination.records }} </template>

      <template #rightOfTitle>
        <v-btn @click="openModal" color="primary" small>
          <v-icon left>mdi-plus</v-icon>
          Add
        </v-btn>
      </template>

      <template #right>
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <v-card>
      <v-data-table
        v-if="tableProps.items.length"
        v-bind="tableProps"
        v-on="tableListeners"
        class="clickable-rows"
        @click:row="
          ({ id }) => $router.push({ name: 'mentorDetail', params: { id } })
        "
      />

      <no-data-screen
        v-else
        :text="noDataText"
        :noun="noun"
        @click="openModal()"
      />
    </v-card>

    <new-mentor />
  </div>
</template>

<script>
import onWindowFocus from "@cp/mixins/onWindowFocus";

import { table } from "@/store/modules/mentors";
import { newMentorModal } from "@/store/modules/mentors";

export default {
  mixins: [
    table.mixin,
    newMentorModal.modalMixin,
    onWindowFocus(function() {
      this.fetchItems();
    }),
  ],
  beforeRouteEnter(to, from, next) {
    next(vm => vm.refreshTable());
  },
};
</script>
