<template>
  <div class="position-relative">
    <Doughnut v-bind="$attrs" />
    <div class="chart-total">
      <div class="text-h6 mb-0">{{ label }}</div>
      <div class="text-h4">{{ total }}</div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";

// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
//   CategoryScale,
// } from "chart.js";

// ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    total: { required: true },
    label: { default: "Total" },
  },
};
</script>

<style lang="scss" scoped>
.chart-total {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
