import {
  Mixin,
  FormModal,
  mergeMixins,
  ItemsTable,
  ItemForm,
  Item,
  HaystackSearch,
} from "@cp/store/mixins";
import { modalAddon } from "@cp/store/addons";
import { eventbus } from "@cp/lib";
import { required, requiredIf } from "@cp/utils/rules";
import { get } from "@cp/utils/objectUtils";
import { wait } from "@cp/utils/promiseUtils";
import { parse, toYMD } from "@cp/utils/dateUtils";

const fiverUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1`;

export const tables = new Mixin({
  module: "taskAssignments",
  baseUrl: `${fiverUrl}/task_assignments/autocomplete`,
});

class TaskBulkActionModal extends FormModal {
  constructor(config) {
    super({
      ...config,
      baseUrl: `${fiverUrl}/task_assignments/bulk_update`,
      parent: tables,
      defaultRequestConfig: { method: "PUT" },
      close: ({ commit }) => {
        commit(this.keys.reset);
        eventbus.$emit("BulkTaskModalClosed");
      },
    });
  }
}

export const bulkComplete = new TaskBulkActionModal({
  name: "complete",
  module: "bulkComplete",
  fields: {
    admin_action: { initialValue: "complete" },
    ids: { initialValue: [] },
    completed_by: { rules: [required] },
    completed_at: { rules: [required], initialValue: toYMD() },
    note: {},
  },
});

export const bulkReOpen = new TaskBulkActionModal({
  name: "reOpen",
  module: "bulkReOpen",
  fields: {
    admin_action: { initialValue: "re_open" },
    ids: { initialValue: [] },
    due_by_date: { initialValue: "" },
    note: {},
  },
});

export const bulkExtendDueDate = new TaskBulkActionModal({
  name: "extendDueDate",
  module: "bulkExtendDueDate",
  fields: {
    admin_action: { initialValue: "extend_due_date" },
    ids: { initialValue: [] },
    due_by_date: { initialValue: "", rules: [required] },
  },
});

export const bulkAddAssignees = new TaskBulkActionModal({
  name: "addAssignees",
  module: "bulkAddAssignees",
  fields: {
    admin_action: { initialValue: "add_assignees" },
    ids: { initialValue: [] },
    assignee_ids: { initialValue: [], rules: [required] },
  },
});

export const bulkReAssign = new TaskBulkActionModal({
  name: "addAssignees",
  module: "bulkReAssign",
  fields: {
    admin_action: { initialValue: "re_assign" },
    ids: { initialValue: [] },
    assignee_ids: { initialValue: [], rules: [required] },
  },
});

export const bulkCancel = new TaskBulkActionModal({
  name: "cancel",
  module: "bulkCancel",
  fields: {
    admin_action: { initialValue: "cancel" },
    ids: { initialValue: [] },
  },
});

export const bulkUnCancel = new TaskBulkActionModal({
  name: "unCancel",
  module: "bulkUnCancel",
  fields: {
    admin_action: { initialValue: "un_cancel" },
    ids: { initialValue: [] },
  },
});

export const bulkSendBack = new TaskBulkActionModal({
  name: "sendBack",
  module: "bulkSendBack",
  fields: {
    admin_action: { initialValue: "send_back" },
    ids: { initialValue: [] },
    due_by_date: { initialValue: "", rules: [required] },
    note: { rules: [required] },
  },
});

export const bulkAddNote = new TaskBulkActionModal({
  name: "addNote",
  module: "bulkAddNote",
  fields: {
    admin_action: { initialValue: "add_note" },
    ids: { initialValue: [] },
    note: { rules: [required] },
  },
});

export const bulkActions = {
  complete: {
    name: "complete",
    subModule: bulkComplete,
    modalComponent: "bulk-complete-task",
  },
  reOpen: {
    name: "Re-open",
    subModule: bulkReOpen,
    modalComponent: "bulk-re-open-task",
  },
  extendDueDate: {
    name: "Extend due date",
    article: "for",
    subModule: bulkExtendDueDate,
    modalComponent: "bulk-extend-task-due-date",
  },
  addAssignees: {
    name: "Add assignees",
    article: "to",
    subModule: bulkAddAssignees,
    modalComponent: "bulk-add-assignees-to-task",
  },
  reAssign: {
    name: "Re-assign",
    subModule: bulkReAssign,
    modalComponent: "bulk-re-assign-task",
  },
  cancel: {
    name: "Cancel",
    subModule: bulkCancel,
    formComponent: "bulk-task-table",
    explanation: "Are you sure you want to cancel these tasks?",
  },
  unCancel: {
    name: "Un-cancel",
    subModule: bulkUnCancel,
    formComponent: "bulk-task-table",
    explanation: "Are you sure you want to un-cancel these tasks?",
  },
  sendBack: {
    name: "Send Back",
    subModule: bulkSendBack,
    modalComponent: "bulk-send-back-task",
  },
  addNote: {
    name: "Add Note",
    subModule: bulkAddNote,
    modalComponent: "bulk-add-note-to-task",
  },
};

tables.add({
  modules: {
    bulkComplete,
    bulkReOpen,
    bulkExtendDueDate,
    bulkAddAssignees,
    bulkReAssign,
    bulkCancel,
    bulkUnCancel,
    bulkSendBack,
    bulkAddNote,
  },
});

export const openTable = new ItemsTable({
  parent: tables,
  module: "openTable",
  baseUrl: `${fiverUrl}/task_assignments/open`,
  filters: [
    "q_text",
    {
      label: "Task",
      type: "sub_query",
      key: "tasks",
      multiple: true,
    },
    {
      label: "Assignment Group",
      type: "sub_query",
      key: "assignment_groups",
      multiple: true,
    },
    {
      label: "Plan",
      type: "sub_query",
      key: "user_task_plans",
      multiple: true,
    },
    {
      label: "Type",
      type: "sub_query",
      key: "user_task_types",
      multiple: true,
    },
    {
      label: "Employee",
      type: "sub_query",
      key: "employees",
      multiple: true,
    },
    {
      label: "Location of Employee",
      type: "sub_query",
      key: "locations",
      multiple: true,
    },
    {
      label: "Progress Statuses",
      type: "sub_query",
      key: "progress_statuses",
      multiple: true,
    },
    {
      label: "Assignee",
      type: "sub_query",
      key: "assignees",
      multiple: true,
    },
    {
      label: "Location of Assignee",
      type: "sub_query",
      key: "assignee_locations",
      multiple: true,
    },
  ],
  bulkActions: [
    bulkActions.addNote,
    bulkActions.complete,
    bulkActions.cancel,
    bulkActions.extendDueDate,
    bulkActions.addAssignees,
    bulkActions.reAssign,
  ],
  noun: "task",
  headers: [
    { value: "data-table-select" },
    {
      text: "✓",
      sortable: false,
      value: "completion",
      width: 60,
      align: "center",
    },
    {
      text: "Task",
      value: "task.title",
      sortable: true,
      cellClass: "clickable-cell",
    },
    // from Joe -- too much text
    // {
    //   text: "Details",
    //   sortable: false,
    //   value: "explanation",
    //   cellClass: "text-no-wrap",
    //   width: "30%",
    // },
    {
      text: "Employee",
      sortable: false,
      value: "on_behalf_of",
    },
    {
      text: "Assignee",
      sortable: false,
      value: "assignees",
    },
    {
      text: "Due",
      value: "due_by_date",
      cellClass: "text-no-wrap",
      width: 80,
      sortable: true,
    },
    {
      text: "Notes",
      value: "notes",
      sortable: false,
      width: 80,
    },
  ],
});

export const unassignedTable = new ItemsTable({
  parent: tables,
  module: "unassignedTable",
  baseUrl: `${fiverUrl}/task_assignments/unassigned`,
  filters: [
    "q_text",
    {
      label: "Task",
      type: "sub_query",
      key: "tasks",
      multiple: true,
    },
    {
      label: "Assignment Group",
      type: "sub_query",
      key: "assignment_groups",
      multiple: true,
    },
    {
      label: "Plan",
      type: "sub_query",
      key: "user_task_plans",
      multiple: true,
    },
    {
      label: "Type",
      type: "sub_query",
      key: "user_task_types",
      multiple: true,
    },
    {
      label: "Employee",
      type: "sub_query",
      key: "employees",
      multiple: true,
    },
    {
      label: "Location of Employee",
      type: "sub_query",
      key: "locations",
      multiple: true,
    },
    {
      label: "Progress Statuses",
      type: "sub_query",
      key: "progress_statuses",
      multiple: true,
    },
    {
      label: "Assignee",
      type: "sub_query",
      key: "assignees",
      multiple: true,
    },
    {
      label: "Location of Assignee",
      type: "sub_query",
      key: "assignee_locations",
      multiple: true,
    },
  ],
  bulkActions: [
    bulkActions.addNote,
    bulkActions.complete,
    bulkActions.cancel,
    bulkActions.extendDueDate,
    bulkActions.addAssignees,
    bulkActions.reAssign,
  ],
  noun: "task",
  headers: [
    { value: "data-table-select" },
    {
      text: "✓",
      sortable: false,
      value: "completion",
      width: 60,
      align: "center",
    },
    {
      text: "Task",
      value: "task.title",
      sortable: true,
      cellClass: "clickable-cell",
    },
    // from Joe -- too much text
    // {
    //   text: "Details",
    //   sortable: false,
    //   value: "explanation",
    //   cellClass: "text-no-wrap",
    //   width: "40%",
    // },
    {
      text: "Employee",
      sortable: false,
      value: "on_behalf_of",
    },
    {
      text: "Due",
      value: "due_by_date",
      cellClass: "text-no-wrap",
      width: 80,
      sortable: true,
    },
    {
      text: "Notes",
      value: "notes",
      sortable: false,
      width: 80,
    },
  ],
});

export const historicalTable = new ItemsTable({
  parent: tables,
  module: "historicalTable",
  baseUrl: `${fiverUrl}/task_assignments/historical`,
  filters: [
    "q_text",
    {
      label: "Task",
      type: "sub_query",
      key: "tasks",
      multiple: true,
    },
    {
      label: "Assignment Group",
      type: "sub_query",
      key: "assignment_groups",
      multiple: true,
    },
    {
      label: "Plan",
      type: "sub_query",
      key: "user_task_plans",
      multiple: true,
    },
    {
      label: "Type",
      type: "sub_query",
      key: "user_task_types",
      multiple: true,
    },
    {
      label: "Employee",
      type: "sub_query",
      key: "employees",
      multiple: true,
    },
    {
      label: "Location of Employee",
      type: "sub_query",
      key: "locations",
      multiple: true,
    },
    {
      label: "Compliance Statuses",
      type: "sub_query",
      key: "compliance_statuses",
      multiple: true,
    },
    {
      label: "Assignee",
      type: "sub_query",
      key: "assignees",
      multiple: true,
    },
    {
      label: "Location of Assignee",
      type: "sub_query",
      key: "assignee_locations",
      multiple: true,
    },
  ],
  bulkActions: [
    bulkActions.addNote,
    bulkActions.cancel,
    bulkActions.reOpen,
    // bulkActions.sendBack, // mostly a duplicate of reOpen
  ],
  noun: "task",
  headers: [
    { value: "data-table-select" },
    {
      text: "✓",
      sortable: false,
      value: "completion",
      width: 60,
      align: "center",
    },
    {
      text: "Task",
      value: "task.title",
      sortable: true,
      cellClass: "clickable-cell",
    },
    // from Joe -- too much text
    // {
    //   text: "Details",
    //   sortable: false,
    //   value: "explanation",
    //   cellClass: "text-no-wrap",
    //   width: "40%",
    // },
    {
      text: "Employee",
      sortable: false,
      value: "on_behalf_of",
    },
    {
      text: "Completed",
      sortable: false,
      value: "completed_by",
    },
    {
      text: "Completed On",
      value: "completed_at",
      cellClass: "text-no-wrap",
      width: 80,
      sortable: true,
    },
    {
      text: "Notes",
      value: "notes",
      sortable: false,
      width: 80,
    },
  ],
});

tables.add({
  modules: { openTable, unassignedTable, historicalTable },
});

export const adminActions = {
  complete: {
    fieldsRequired: {
      completed_by: true,
      completed_at: true,
      note: false,
    },
    text: "Mark Complete",
    icon: "mdi-check-bold",
    buttonText: "Mark task as completed",
    successText: "Task marked as completed!",
  },
  re_open: {
    fieldsRequired: {
      due_by_date: false,
      note: false,
    },
    text: "Re-Open",
    icon: "mdi-undo-variant",
    explanation:
      "Marks the item as pending, so that the assignees can complete it again.",
    successText: "Task re-opened!",
  },
  extend_due_date: {
    fieldsRequired: {
      due_by_date: true,
    },
    text: "Extend Due Date",
    icon: "mdi-calendar-end-outline",
    buttonText: "Extend task's due date",
    successText: "Task's due date extended!",
  },
  add_assignees: {
    fieldsRequired: {
      assignee_ids: true,
    },
    text: "Add Assignees",
    icon: "mdi-account-plus",
    explanation:
      "This will add new assignees without changing the existing ones.",
    buttonText: "Add assignees to task",
    successText: "Assignees added!",
  },
  re_assign: {
    fieldsRequired: {
      assignee_ids: true,
    },
    text: "Re-Assign",
    icon: "mdi-account-arrow-right",
    explanation:
      "This will remove current assignees and assign the the task to the ones you select.",
    buttonText: "Add assignees to task",
    successText: "Assignees added!",
  },
  cancel: {
    fieldsRequired: {},
    text: "Mark Cancelled",
    icon: "mdi-cancel",
    explanation: "Cancels the item, so it doesn't show up in assignee's lists.",
    buttonText: "Mark task as cancelled",
    successText: "Task cancelled!",
  },
  un_cancel: {
    fieldsRequired: {},
    text: "Un Cancel",
    icon: "mdi-arrow-left-top",
    explanation:
      "This will undo the cancelation. The task will show up in assignee's list again.",
  },
  send_back: {
    fieldsRequired: {
      due_by_date: true,
      note: true,
    },
    text: "Send back",
    icon: "mdi-account-arrow-left",
    explanation:
      "Marks the item as pending, so that the assignees can complete it again.",
    buttonText: "Send task back to assignees",
    successText: "Task sent back!",
  },
  add_note: {
    fieldsRequired: {
      note: true,
    },
    text: "Add Note",
    icon: "mdi-pencil-box-outline",
    buttonText: "Add note to task",
    successText: "Note added!",
  },
};

export function requiredIfFieldIsRequiredByAdminAction(field) {
  return requiredIf(function({ admin_action }) {
    return adminActions[admin_action].fieldsRequired[field];
  });
}

export const itemFormModal = new ItemForm({
  module: "taskAssignments",
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/task_assignments`,
  url: "/:id",
  urlTemplate: true,
  initialValue: {
    completed_at: "",
    admin_actions: [],
    task: {},
    on_behalf_of: {
      full_name: "",
    },
    assignees: [],
    plans: [],
    files: [],
    due_by_date: "",
    abandon_date: "",
    escalate_date: "",
    user_task_progress_status_id: 1,
    user_task_compliance_status_id: 0,
    notes: [],
    assignment_overwritten: false,
    reason_for_unassignment: {
      message: "",
      link: "",
    },
  },
  fields: {
    admin_action: {
      initialValue: "add_note",
      resetOthers: ["completed_by", "completed_at"],
    },
    assignee_ids: {
      initialValue: [],
      rules: [requiredIfFieldIsRequiredByAdminAction("assignee_ids")],
    },
    completed_by: {
      rules: [requiredIfFieldIsRequiredByAdminAction("completed_by")],
    },
    completed_at: {
      initialValue: parse().format("YYYY-MM-DD"),
      rules: [requiredIfFieldIsRequiredByAdminAction("completed_at")],
    },
    due_by_date: {
      initialValue: "",
      rules: [requiredIfFieldIsRequiredByAdminAction("due_by_date")],
    },
    note: {
      rules: [requiredIfFieldIsRequiredByAdminAction("note")],
    },
  },
});
// Give adminActions to module for display
itemFormModal.instantiate({ adminActions });

itemFormModal.add(
  modalAddon({
    modalName: "form",
    open({ dispatch, commit }, { id } = {}) {
      if (id)
        return dispatch("fetchItem", { id }).then(({ data }) => {
          commit(this.keys.input, { admin_action: data.admin_actions[0] });
        });
      else commit(this.keys.reset);
    },
    async close({ commit, state }) {
      await wait(50);
      commit("resetItem");

      // close the history modal when we close the task modal
      state.itemHistoryModal.itemDetailModal = false;
    },
  })
);

export const itemHistoryModal = new Item({
  parent: itemFormModal,
  module: "itemHistoryModal",
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/task_assignments`,
  url: "/:id/audit",
  initialValue: [],
});
itemHistoryModal.add(
  modalAddon({
    modalName: "detail",
    open({ dispatch, rootState }) {
      const id = get(rootState, itemFormModal.p.s.stateKey + ".id");
      if (id) dispatch("fetchItem", { id });
    },
  })
);

itemFormModal.add({
  modules: { itemHistoryModal },
});

export const employeeSearch = new HaystackSearch({
  module: "taskAssignments",
  urlKey: "employees",
  name: "employees",
});

window.$taskTables = tables;

export default mergeMixins(tables, itemFormModal, employeeSearch);
