<template>
  <cp-module-modal
    :modalConf="modalConf"
    @opened="modalOpened"
    v-on="$listeners"
    width="600"
  >
    <v-toolbar dark color="primary" height="52">
      <v-toolbar-title>Add Mentor for {{ item.full_name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small icon class="mr-1" @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card :loading="mentorsLoading" class="modal-content-card">
      <v-card-text>
        <v-text-field
          :v-model="q_text"
          @input="search"
          :label="`Search for mentors to assign to ${item.full_name}`"
          hide-details
          dense
          outlined
          prepend-inner-icon="mdi-magnify"
          class="card-listing-search-field"
        />
        <!-- <v-text-field v-model="search" hide-details dense outlined placeholder="Search"
                    append-icon="mdi-magnify"></v-text-field> -->
        <v-list class="list-add-mentors">
          <Scrollbar
            ref="scrollbar"
            @ps-y-reach-end="loadMore"
            suppress-scroll-x
          >
            <v-list-item
              v-for="employee in selectedButNotInResults"
              :key="`not-in-results-${employee.value}`"
            >
              <v-checkbox
                hide-details
                v-model="ids"
                :label="employee.label"
                :value="employee.value"
              />
            </v-list-item>
            <v-list-item v-for="employee in mentors" :key="employee.value">
              <v-checkbox
                hide-details
                v-model="ids"
                :label="employee.label"
                :value="employee.value"
                @change="() => cache(employee)"
              />
            </v-list-item>

            <v-list-item v-if="mentorsLoading || loadingMore">
              <v-list-item-icon>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-list-item-icon>
              <v-list-item-content>Loading...</v-list-item-content>
            </v-list-item>
            <!-- </v-radio-group> -->
          </Scrollbar>
        </v-list>
      </v-card-text>
      <v-card-text v-if="completeSpinnerTimeout">
        <v-alert color="info">
          Creating mentors can take a few minutes. You can close this modal now.
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-end" v-else>
        <v-btn color="primary" text @click="closeModal">Cancel</v-btn>
        <v-btn
          color="primary"
          @click="submitWaitAndClose"
          :disabled="ids.length === 0"
          >Add
          {{
            ids.length ? pluralize("Mentor", ids.length, true) : "Mentor"
          }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </cp-module-modal>
</template>

<script>
import Scrollbar from "vue-custom-scrollbar";
import { mapActions, mapState, mapMutations } from "vuex";
import pluralize from "pluralize";

import { wait } from "@cp/utils/promiseUtils";
import { get } from "@cp/utils/objectUtils";
import { Client } from "@cp/lib";

import { addMentorModal } from "@/store/modules/employees";

const marigoldApi = new Client({
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`,
});

export default {
  name: "addMentorModal",
  mixins: [addMentorModal.modalMixin, addMentorModal.loadMoreMixin],
  components: { Scrollbar },
  data: () => ({
    q_text: "",
    ids: [],
    waiting: false,
    completeSpinnerTimeout: null,
  }),
  computed: {
    ...mapState(addMentorModal.mp, ["mentors", "mentorsLoading"]),
    ...mapState("employees", ["item"]),
    selectedButNotInResults() {
      const cache = get(
        this.$store.state,
        addMentorModal.p.s.selectedItemsCache
      );
      const showingEmployeeIds = this.mentors.map(x => x.value);
      const selectedIdsNotInShowing = this.ids.filter(
        id => !showingEmployeeIds.includes(id)
      );
      return selectedIdsNotInShowing.map(id => cache[id]);
    },
  },
  methods: {
    pluralize,
    ...mapActions(addMentorModal.mp, {
      search: addMentorModal.keys.search,
    }),
    ...mapMutations(addMentorModal.mp, {
      cache: addMentorModal.keys.addToCache,
    }),
    async submitWaitAndClose() {
      const payload = {
        mentees: [{ mentee_id: this.item.id, mentor_ids: this.ids }],
      };
      const response = await marigoldApi.put("users/add_mentors", payload);
      this.$CpEvent.$emit("snackAlert", {
        message: `Adding ${pluralize(
          "Mentor",
          this.ids.length,
          true
        )}! This may take a few moments`,
        color: "success",
      });
      this.closeModal({ success: true });
    },
    async modalOpened() {
      this.waiting = false;
      this.ids = [];
      this.search();
      await wait(10);
      // for some reason, it has a x-scroll. this kills that
      this.$refs.scrollbar.$el.scrollTo(0, 1);
      await wait(10);
      this.$refs.scrollbar.$el.scrollTo(0, 0);
    },
    modalClosed() {
      clearTimeout(this.completeSpinnerTimeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-add-mentors.v-list::v-deep {
  border: 1px solid #afb4c5;
  border-radius: 4px;
  height: calc(100vh - 400px);
  padding: 0;
  margin-top: 1rem;

  > .ps {
    height: 100%;
    overflow-x: hidden !important;
  }

  .v-list-item {
    padding: 0;
    min-height: auto;
    align-items: stretch;

    & + .v-list-item {
      border-top: 1px solid #afb4c5;
    }

    &__action {
      margin: 0;

      &:last-child {
        margin-left: 0;
      }
    }
  }

  .v-input,
  .v-input__slot {
    margin: 0;
  }

  .v-input {
    padding: 0;

    &--checkbox {
      margin: 0;
      width: calc(100% - 2.5rem);

      & > label {
        color: #52555e;
      }
    }

    &__slot {
      padding: 1rem;
    }
  }
}
</style>
