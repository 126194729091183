<template>
  <cp-toolbar-module-modal :modalConf="modalConf" v-on="$listeners" width="680">
    <template #title
      >Edit {{ `${item.first_name} ${item.last_name}` }}'s info</template
    >
    <cp-loading :loading="loading">
      <v-row class="dense-vertical">
        <v-col cols="6">
          <cp-search-autocomplete
            :form="form"
            :searchModule="locationsSearch"
            name="location_id"
            v-bind="fields.location_id.attrs"
            @input="fields.location_id.input"
            label="Location"
            :initialValue="
              item.location_id
                ? {
                    value: item.location_id,
                    label: item.location,
                  }
                : null
            "
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <cp-search-autocomplete
            :form="form"
            :searchModule="positionsSearch"
            name="position_id"
            v-bind="fields.position_id.attrs"
            @input="fields.position_id.input"
            label="Position"
            :initialValue="
              item.position_id
                ? {
                    value: item.position_id,
                    label: item.position,
                  }
                : null
            "
            outlined
            dense
          />
        </v-col>

        <v-col cols="6">
          <cp-date-picker
            v-bind="fields.start_date.attrs"
            @input="fields.start_date.input"
            :defaultDaysInFuture="false"
            label="Start Date"
            outlined
            dense
          />
        </v-col>
      </v-row>

      <cp-unhandled-errors :formConf="formConf" />

      <cp-form-modal-success-or-buttons
        :modalConf="modalConf"
        :formConf="formConf"
        :buttons="['cancel', 'update']"
        @submit="submit"
        noun="Employee"
        success-message="Employee updated!"
      />
    </cp-loading>
  </cp-toolbar-module-modal>
</template>

<script>
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import VueDropzone from "vue2-dropzone";
import { mapState, mapGetters } from "vuex";

import {
  editEmployeeModal,
  locationsSearch,
  positionsSearch,
} from "@/store/modules/employees";
import { wait } from "@cp/utils/promiseUtils";
import { get } from "@cp/utils/objectUtils";

export default {
  mixins: [editEmployeeModal.modalMixin, editEmployeeModal.mixin],
  data() {
    return {
      locationsSearch,
      positionsSearch,
    };
  },
  computed: {
    ...mapState(editEmployeeModal.mp, ["item"]),
    small() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    async submit() {
      const config = {
        method: "PUT",
        id: this.item.id,
        url: "/:id/introduce_update",
      };
      const response = await this.itemSubmit(config);

      if (!this.success) return;

      const id = get(response, "data.user_id");
      await wait(1500);
      this.closeModal({ success: true, id });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.dense-vertical) {
  margin-top: -5px;
  margin-bottom: -5px;

  & > div {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.profileImageArea {
  position: relative;
  height: 160px;
}

.imageHandle {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: 24px;
}

:deep(.vue-dropzone.dropzone) {
  min-height: 116px;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: content-box;
  padding: 0;

  &.dz-drag-hover {
    border: 2px solid $primary;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    .dz-message {
      pointer-events: none;
    }
  }

  &.small {
    border: none;
  }

  .dz-message {
    margin: 0;
  }

  .dz-preview.dz-processing {
    height: 116px;
    width: 116px;
    margin: 0;
    .dz-image img {
      width: 100%;
      height: 100%;
    }
  }
}
.animateDragDrop {
  transition: translate;
  transform: translate(0, 0) scale(1);
  animation: drag-drop 4s ease-in-out infinite;
  color: transparent;
  &:before {
    color: $gray-4;
  }
  &:after {
    background: none;
    background-color: currentColor;
    opacity: 1;
    z-index: -1;
  }
}

$noClickBg: rgba($primary, 0);
$clickBg: rgba($primary, 0.25);

@keyframes drag-drop {
  0% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(15px, 30px);
  }
  21% {
    color: $noClickBg;
  }
  22% {
    color: $clickBg;
  }
  30% {
    transform: translate(15px, 30px);
  }
  50% {
    transform: translate(0, 0);
  }
  69% {
    color: $clickBg;
  }
  70% {
    color: $noClickBg;
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
