<template>
  <cp-toolbar-module-modal
    :modalConf="modalConf"
    v-on="$listeners"
    width="800"
    root-slot
  >
    <template #title>Update Task</template>
    <v-card tile>
      <v-card-title class="pr-0 py-0 align-center flex-nowrap">
        <div>
          {{ item.task.title }}
        </div>
        <cp-explain v-if="item.assignment_overwritten">
          <template #activator="{on}">
            <v-icon color="warning" v-on="on" class="ml-5"
              >mdi-lock-open-alert-outline</v-icon
            >
          </template>
          This tasks's assignments were manually modified. You can view the
          history to see more details.
          <br /><br />
          This task will no longer automaically update assignees.
        </cp-explain>
        <v-spacer />
        <v-btn
          @click="toggleHistory"
          class="history-btn"
          tile
          :text="!historyIsOpen"
          :dark="historyIsOpen"
          :color="historyIsOpen ? '#9063cd' : ''"
        >
          <template v-if="historyIsOpen">
            <v-icon>mdi-close</v-icon>
            <div>
              HIDE TASK HISTORY
            </div>
          </template>
          <template v-else>
            <v-icon>mdi-history</v-icon>
            <div>
              SHOW TASK HISTORY
            </div>
          </template>
        </v-btn>
      </v-card-title>

      <v-expand-transition>
        <v-card
          v-show="historyIsOpen"
          class="task-history"
          dark
          tile
          color="#9063cd"
        >
          <v-card-title>
            <v-icon>mdi-history</v-icon>
            History
          </v-card-title>
          <v-card-text>
            <task-history />
          </v-card-text>
        </v-card>
      </v-expand-transition>

      <v-divider horizontal />

      <v-card-text class="pt-4">
        <template v-if="item.task.description">
          <strong>Description:</strong>
          <div v-html="item.task.description" class="displayHTML" />
        </template>

        <span v-if="item.assignees.length === 1">
          <strong>Assigned to:</strong> {{ item.assignees[0].name }}
        </span>
        <span v-else-if="item.assignees.length > 1">
          <strong>Assigned to:</strong>
          <cp-explain>
            <template #activator="{on}">
              <v-chip v-on="on" class="text-no-wrap" small>
                {{ pluralize("Employee", item.assignees.length, true) }}
              </v-chip>
            </template>

            <cp-profile-list :profiles="item.assignees" dense />
          </cp-explain>
          on
        </span>
        <cp-explain v-else>
          <template #activator="{on}">
            <span>
              <strong v-on="on" class="red--text">
                Task is unassigned
              </strong>
              (created on)
            </span>
          </template>

          <p>{{ item.reason_for_unassignment.message }}</p>

          <template #actions v-if="unnassignedButton">
            <v-btn v-bind="unnassignedButton.props">
              <v-icon v-if="unnassignedButton.icon" left>{{
                unnassignedButton.icon
              }}</v-icon>
              {{ unnassignedButton.text }}
            </v-btn>
          </template>
        </cp-explain>
        {{ dates.created_at }}

        <br />
        <strong>Employee:</strong>
        {{ item.on_behalf_of.full_name }}
        <br />

        <template v-if="item.files.length">
          <strong>Attachments:</strong>
          <cp-file-chip
            v-for="file in item.files"
            :key="file.id"
            :name="file.name"
            :url="file.url"
            class="ma-1"
            small
          />
          <br />
        </template>

        <strong>Due:</strong>
        {{ dates.due_by_date }}
        <span
          v-if="dates.dueDatePassed && !item.completed_at"
          class="red--text"
        >
          ({{ pluralize("day", dates.dueDaysAgo, true) }} ago)
        </span>

        <br />

        <strong>Escalation Date:</strong>
        {{ dates.escalate_date }}

        <br />

        <strong>Abandon Date:</strong>
        {{ dates.abandon_date }}

        <br />

        <template v-if="item.completed_at">
          <template v-if="item.completed_by_name">
            <strong>Completed By:</strong>
            {{ item.completed_by_name }}
          </template>
          <strong v-else>Completed</strong>
          on {{ dates.completed_at }}
          <span v-if="dates.completedAfterDue" class="red--text">
            ({{ pluralize("day", dates.completedAfterDueDays, true) }} late)
          </span>
        </template>
      </v-card-text>

      <v-alert :icon="status.icon" v-bind="status.chipProps" tile>
        <strong>{{ status.title }}</strong>
        <br />
        <span v-if="item.user_task_progress_status_id === 1">
          will alert on {{ dates.alert }}
        </span>
        <span v-if="item.user_task_progress_status_id === 2">
          due
          <span v-if="daysFrom.due > 0">
            in {{ pluralize("day", daysFrom.due, true) }}
          </span>
          <span v-else>today</span>
        </span>
        <span v-if="item.user_task_progress_status_id === 3">
          {{ pluralize("days", dates.dueDaysAgo, true) }} late
        </span>
        <span v-if="item.user_task_compliance_status_id === 1">
          Completed on time by
          {{ item.completed_by_name }}
        </span>
        <span v-if="item.user_task_compliance_status_id === 2">
          Completed
          {{ pluralize("days", dates.completedAfterDueDays, true) }} late by
          {{ item.completed_by_name }}
        </span>
        <span v-if="item.user_task_compliance_status_id === 3">
          Marked <span class="font-weight-black">Can't Complete</span> by
          {{ item.completed_by_name }} on
          {{ dates.completed_at }}
        </span>
        <span v-if="item.user_task_compliance_status_id === 4">
          Abandoned on {{ dates.abandon_date }}
        </span>
        <span v-if="item.user_task_compliance_status_id === 5">
          Marked
          <span class="font-weight-black">Canceled</span>
          {{
            item.admin_note ? item.admin_note.replace(/^Cancell*ed /, "") : ""
          }}
          on
          {{ dates.adminNote }}
        </span>
      </v-alert>

      <v-card-text v-if="item.notes.length" class="my-4 py-1">
        <v-alert
          v-for="(note, i) in item.notes"
          :key="`note-${i}`"
          color="blue-grey"
          class="note"
          text
        >
          <div class="note-attribution text-caption">
            {{ note.user }} at {{ toTIME(note.created_at) }}
            {{ toMDY(note.created_at) }}
          </div>
          {{ note.note }}
        </v-alert>
      </v-card-text>

      <v-card-text v-if="adminActionButtons.length" class="mt-4 pt-1">
        <!-- note from Joe:
             these buttons are confusing, and compete with the button at the bottom of the modal.
             Commenting them out for now, and adding explanation text, to see if that helps.
        -->
        <!-- <v-btn-toggle
          v-if="adminActionButtons.length <= 3"
          v-bind="fields.admin_action.attrs"
          @change="handleAdminActionChange"
          class="mb-4"
          mandatory
        >
          <v-btn
            v-for="{
              action,
              icon,
              text,
              buttonProps = {},
            } in adminActionButtons"
            :key="action"
            v-bind="buttonProps"
            :value="action"
          >
            <v-icon v-if="icon">{{ icon }}</v-icon>
            {{ text || action }}
          </v-btn>
        </v-btn-toggle> -->
        <v-row>
          <v-col sm="6">
            <v-select
              v-bind="fields.admin_action.attrs"
              @change="handleAdminActionChange"
              :items="adminActionButtons"
              item-value="action"
              label="Update Task"
              outlined
              dense
              :hint="selectedAdminAction.explanation"
              persistent-hint
            >
              <template #item="{item}">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ item.text }}
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-autocomplete
          v-if="showAdminActionField.completed_by"
          v-bind="fields.completed_by.attrs"
          @input="fields.completed_by.input"
          label="Completed By:"
          :items="completedByOptions"
          outlined
          ref="completedBy"
          @change="$refs.completedBy.internalSearch = ''"
        />

        <cp-search-autocomplete
          v-if="showAdminActionField.assignee_ids"
          v-bind="fields.assignee_ids.attrs"
          :form="itemFormModal"
          :searchModule="employeeSearch"
          :params="{ filters: { not_ids: item.assignees.map(x => x.id) } }"
          @input="fields.assignee_ids.input"
          @change="$refs.newAssignees.internalSearch = ''"
          label="New Assignees:"
          ref="newAssignees"
          name="assignee_ids"
          multiple
          outlined
        />

        <cp-date-picker
          v-if="showAdminActionField.due_by_date"
          v-bind="fields.due_by_date.attrs"
          @input="fields.due_by_date.input"
          label="New Due Date:"
          outlined
          :datePickerProps="{ allowedDates: newDueDateAllowed }"
        />

        <cp-date-picker
          v-if="showAdminActionField.completed_at"
          v-bind="fields.completed_at.attrs"
          @input="fields.completed_at.input"
          label="Completed On:"
          outlined
        />

        <v-textarea
          v-if="showAdminActionField.note"
          label="Enter your note here"
          v-bind="fields.note.attrs"
          @input="fields.note.input"
          auto-grow
          outlined
          rows="5"
          row-height="25"
          dense
        />

        <cp-form-modal-success-or-buttons
          v-bind="buttonsProps"
          @submit="submit"
        />
      </v-card-text>
    </v-card>
  </cp-toolbar-module-modal>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import pluralize from "pluralize";

import {
  itemFormModal,
  itemHistoryModal,
  employeeSearch,
} from "@/store/modules/taskAssignments";
import {
  complianceStatusesById,
  progressStatusesById,
} from "@/store/modules/tasks";
import { parse, toMDY, toTIME } from "@cp/utils/dateUtils";
import { get } from "@cp/utils/objectUtils";
import { wait } from "@cp/utils/promiseUtils";
import { onCpEvent } from "@cp/mixins";

const TODAY = parse();

export default {
  name: "TaskAssignmentStatusFormModal",
  mixins: [
    itemFormModal.mixin,
    itemFormModal.modalMixin,
    onCpEvent("snackAlertButtonClicked", function(args) {
      if (args.modalId === "TaskAssignmentStatusForm") {
        this.openModal({ id: args.id });
      }
    }),
  ],
  data() {
    return { itemFormModal, employeeSearch };
  },
  computed: {
    ...mapState(itemHistoryModal.mp, { historyIsOpen: "itemDetailModal" }),
    ...mapGetters("auth", ["userId", "userName"]),
    dates() {
      const now = parse();
      const due = parse(this.item.due_by_date);
      const completed = parse(this.item.completed_at);
      return {
        now,
        due,
        created_at: toMDY(this.item.created_at),
        completed_at: toMDY(this.item.completed_at),
        updated_at: toMDY(this.item.updated_at),
        due_by_date: toMDY(this.item.due_by_date),
        escalate_date: toMDY(this.item.escalate_date),
        abandon_date: toMDY(this.item.abandon_date),
        dueDatePassed: now.isAfter(due, "day"),
        dueDaysAgo: now.diff(due, "days"),
        completedAfterDue: completed.isAfter(due, "day"),
        completedAfterDueDays: completed.diff(due, "days"),
        adminNote: toMDY(this.item.admin_note_created_at),
        alert: toMDY(this.item.alert_date),
      };
    },
    daysFrom() {
      const today = parse().startOf("day");
      const alert = parse(this.item.alert_date).startOf("day");
      const due = parse(this.item.due_by_date).startOf("day");
      return {
        alert: alert.diff(today, "days"),
        due: due.diff(today, "days"),
      };
    },
    completedByOptions() {
      if (this.$refs.completedBy && this.$refs.completedBy.internalSearch)
        return this.employeeOptions;
      return [
        { text: `${this.userName} (you)`, value: this.userId },
        ...this.item.assignees.map(ass => ({
          text: ass.name,
          value: ass.id,
        })),
      ];
    },
    adminActionButtons() {
      return this.item.admin_actions.map(action => ({
        action,
        ...itemFormModal.adminActions[action],
      }));
    },
    selectedAdminAction() {
      return itemFormModal.adminActions[this.itemFormData.admin_action];
    },
    showAdminActionField() {
      const showFields = Object.keys(this.selectedAdminAction.fieldsRequired);
      return itemFormModal.fieldKeys.reduce((r, key) => {
        r[key] = showFields.includes(key);
        return r;
      }, {});
    },
    status() {
      if (this.item.user_task_progress_status_id === 4)
        return complianceStatusesById[this.item.user_task_compliance_status_id];
      else return progressStatusesById[this.item.user_task_progress_status_id];
    },
    buttonsProps() {
      const SAA = this.selectedAdminAction;
      const text = SAA.buttonText ? SAA.buttonText : SAA.text;
      return {
        modalConf: this.modalConf,
        formConf: this.formConf,
        buttons: ["cancel", "update"],
        noun: SAA.buttonText ? "" : "Task",
        successMessage: SAA.successText ? SAA.successText : "Task Updated!",
        buttonConf: {
          update: {
            text,
            icon: SAA.icon,
          },
        },
      };
    },
    unnassignedButton() {
      const link = get(this.item, "reason_for_unassignment.link");
      if (!link) return false;
      const linkProps = link[0] === "/" ? { to: link } : { href: link };
      return {
        props: { ...linkProps, color: "primary" },
        text: "Fix Issue",
        icon: "mdi-open-in-new",
      };
    },
  },
  methods: {
    pluralize,
    toMDY,
    toTIME,
    ...mapActions(itemHistoryModal.mp, {
      fetchHistory: "fetchItem",
      closeHistory: "closeItemDetailModal",
      openHistory: "openItemDetailModal",
    }),
    toggleHistory() {
      if (this.historyIsOpen) {
        this.closeHistory();
      } else {
        this.openHistory();
      }
    },
    async submit() {
      const $vm = this;
      const id = this.item.id;
      await this.itemSubmit({ id, method: "PUT" });
      if ($vm.success) {
        await wait(1500);
        $vm.$CpEvent.$emit("snackAlert", {
          message: $vm.selectedAdminAction.successText,
          color: "success",
          timeout: 100000,
          button: {
            text: "View Task",
            args: { id, modalId: "TaskAssignmentStatusForm" },
          },
        });
        $vm.closeModal({ success: true });
      }
    },
    handleAdminActionChange(value) {
      this.fields.admin_action.input(value);
      if (value === "complete") {
        this.$nextTick(() => {
          this.fields.completed_by.input(this.userId);
          this.fields.completed_at.input(parse().format("YYYY/MM/DD"));
        });
      }
    },
    newDueDateAllowed(value) {
      const v = parse(value);
      return TODAY < v;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
  .v-btn.v-btn--active {
    color: white !important;
    border-color: $primary;
    box-shadow: none;
    background-color: $primary;
  }

  .v-icon {
    color: inherit !important;
    fill: currentColor !important;
    &:first-child {
      margin-right: 8px;
    }
  }
}

.v-alert {
  margin-bottom: 0;
}

.v-alert.white--text::v-deep .v-icon {
  color: white;
}

.note {
  margin-top: 22px;

  & + .note {
    margin-top: 30px;
  }

  &:before {
    border-radius: 0 4px 4px 4px;
  }

  .note-attribution {
    position: absolute;
    left: 0;
    bottom: 100%;
    border-radius: 5px 5px 0 0;
    background: #eceff1;
    padding: 0 10px;
    box-shadow: inset 0 -2px 2px -2px rgba(0, 0, 0, 0.3);
  }
}

.history-btn::v-deep {
  height: auto !important;
  padding: 10px !important;
  .v-btn__content {
    flex-direction: column;
    align-items: center;
  }
}

.task-history {
  position: absolute;
  width: 100%;
  z-index: 5;
}

::v-deep .v-select.v-text-field.v-text-field--enclosed .v-text-field__details {
  overflow: visible;
  .v-messages__message {
    white-space: nowrap;
    padding-bottom: 15px;
  }
}
</style>
