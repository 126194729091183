import { mergeMixins, ClientMixin } from "@cp/store/mixins";
import { modalAddon } from "@cp/store/addons";
import { keyBy } from "@cp/utils/arrayUtils";
import { Search } from "../mixins";

const module = "enrollments";
const fiverUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1`;
const baseUrl = `${fiverUrl}/enrollments`;

export const enrollmentStatuses = [
  {
    id: 1,
    label: "Enrolled",
    icon: "mdi-account-check",
  },
  {
    id: 2,
    label: "Unenrolled",
    icon: "mdi-playlist-remove",
  },
  {
    id: 3,
    label: "Canceled",
    icon: "mdi-cancel",
  },
  {
    // this should never show up
    id: 4,
    label: "Purged",
    icon: "mdi-delete-alert-outline",
  },
  {
    id: 5,
    label: "Completed",
    icon: "mdi-check-all",
  },
];
export const enrollmentStatusObj = keyBy(enrollmentStatuses, "id");
export const enrollmentActions = [
  {
    text: "Reenroll",
    icon: "playlist-plus",
    action: "reenroll",
    explanation: "Cancelled tasks are uncanceled",
  },
  {
    text: "Unenroll",
    icon: "playlist-remove",
    action: "unenroll",
    explanation: "Remaining tasks are canceled",
  },
  {
    text: "Purge",
    icon: "delete-alert-outline",
    action: "purge",
    explanation: `All unshared tasks are deleted.<div class="red--text">This cannot be undone</div>`,
    props: { class: "red--text text--darken-2" },
  },
];

const enrollmentClient = new ClientMixin({
  baseUrl,
  actions: {
    unenroll(ctx, { id }) {
      return this.client.put(`/${id}/unenroll`);
    },
    reenroll(ctx, { id }) {
      return this.client.put(`/${id}/reenroll`);
    },
    purge(ctx, { id }) {
      return this.client.delete(`/${id}/purge`);
    },
    enroll(ctx, payload) {
      return this.client.post("", payload);
    },
  },
});

export const enrollmentModal = new Search({
  module,
  baseUrl: `${fiverUrl}/plans/autocomplete`,
  params: {
    page: { size: "all" },
    sort_by: "title",
    filters: { status: [1] },
  },
  mapOptions: x => x,
  name: "plans",
  state: {
    exclude_plan_ids: [],
  },
});
enrollmentModal.add(
  modalAddon({
    modalKey: "enroll",
    open({ state, dispatch }, { exclude_plan_ids = [] } = {}) {
      dispatch(enrollmentModal.keys.fetch);
      state.exclude_plan_ids.splice(
        0,
        state.exclude_plan_ids.length,
        ...exclude_plan_ids
      );
    },
    close({ state }) {
      state.exclude_plan_ids.splice(0, state.exclude_plan_ids.length);
    },
  })
);

export default mergeMixins(enrollmentClient, enrollmentModal);
