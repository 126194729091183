import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',[_vm._v("Dashboard")]),_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c('div',{staticClass:"focus-items"},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v("Focus Items")]),_c(VCard,[_c(VList,{staticClass:"py-0"},_vm._l((_vm.SO.focus_items),function(ref){
var label = ref.label;
var children = ref.children;
return _c(VListItemGroup,{key:("group-" + label)},[_c('div',{staticClass:"px-4 py-2"},[_c('strong',[_vm._v(_vm._s(label))])]),_c(VDivider),_vm._l((children),function(ref){
var label = ref.label;
var link = ref.link;
var value = ref.value;
return _c(VListItem,{key:("item-" + label),staticClass:"focus-item-link",attrs:{"to":link,"color":"primary","dense":""}},[_c(VListItemContent,[_vm._v(_vm._s(label))]),_c(VListItemActionText,[_vm._v(_vm._s(value))])],1)})],2)}),1)],1)],1),_c('div',{staticClass:"compliance mt-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v("Compliance")]),_c(VSpacer),_c(VBtn,{staticClass:"font-weight-light",attrs:{"href":(_vm.bigwig + "/report/task-compliance-report"),"color":"primary","small":"","text":""}},[_vm._v(" View Report ")])],1),_c(VCard,{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"px-4 py-2 d-flex align-center"},[_c('div',{staticClass:"text-caption"},[_vm._v(" Statuses of tasks completed (last 90 days) ")])]),_c(VDivider),_c('div',{staticClass:"pa-10"},[_c('cp-explain',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('doughnut-pie',_vm._b({attrs:{"height":150,"width":150}},'doughnut-pie',_vm.statusPie,false))],1)]}}])},[_c('table',{staticClass:"ml-4"},[_c('tbody',[_vm._l((_vm.complianceStatusCounts),function(ref){
var label = ref.label;
var color = ref.color;
var value = ref.value;
return [_c('tr',{key:label},[_c('td',{staticClass:"stat-label pr-4"},[_c(VIcon,{attrs:{"color":color,"small":""}},[_vm._v("mdi-circle")])],1),_c('td',{staticClass:"stat-label"},[_vm._v(" "+_vm._s(label)+" ")]),_c('td',{staticClass:"text-right pl-4"},[_vm._v(_vm._s(value))])])]})],2)])])],1)],1)],1)]),_c(VCol,{attrs:{"cols":"9"}},[_c('div',{staticClass:"tables"},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v("Enrolled Employees")]),_c(VCard,{staticClass:"d-flex flex-column justify-start"},[_c('div',[_c(VTabs,{attrs:{"slider-color":"primary","background-color":"#f1f1f1","fixed-tabs":""},on:{"change":_vm.changeTab},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},[_c(VTabsSlider),_vm._l((_vm.tabs),function(ref){
var label = ref.label;
var value = ref.value;
return _c(VTab,{key:value},[_vm._v(" "+_vm._s(label)+" ")])})],2)],1),_c('div',{staticClass:"px-4 py-2"},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","clearable":"","hide-details":"","outlined":"","dense":""},on:{"input":_vm.changeSearch},model:{value:(_vm.qText),callback:function ($$v) {_vm.qText=$$v},expression:"qText"}})],1),_c('div',{staticClass:"table-container"},[_c(VDataTable,_vm._g(_vm._b({staticClass:"clickable-rows",attrs:{"hide-default-footer":""},on:{"click:row":_vm.navigateToEmployee},scopedSlots:_vm._u([{key:"item.progress.complete_percentage",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || "0")+"% ")]}}])},'v-data-table',_vm.tableProps,false),_vm.tableListeners))],1),_c(VSpacer),_c(VDivider),(!_vm.noData)?_c(VPagination,_vm._g(_vm._b({staticClass:"mt-2 text-center"},'v-pagination',_vm.paginationProps,false),_vm.paginationListeners)):_vm._e()],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }