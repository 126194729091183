<template>
  <v-card tile>
    <v-card-text class="pt-4">
      <p>
        Change the due date {{ pluralize("Task", selected.length, true) }}? This
        will mark them as pending.
      </p>

      <table class="summary-table mx-auto mb-10">
        <thead>
          <tr>
            <th>Task</th>
            <th>For Employee</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="task in selected" :key="task.id">
            <td>{{ task.task_title }}</td>
            <td>{{ task.on_behalf_of.full_name }}</td>
          </tr>
        </tbody>
      </table>

      <cp-date-picker
        v-bind="fields.due_by_date.attrs"
        @input="fields.due_by_date.input"
        label="New Due Date:"
        outlined
        :defaultDaysInFuture="false"
        :datePickerProps="{ allowedDates: newDueDateAllowed }"
      />
    </v-card-text>
    <v-divider />
    <v-card-actions class="justify-end">
      <cp-form-modal-success-or-buttons
        :modalConf="modalConf"
        :formConf="formConf"
        :buttonConf="buttonConf"
        :buttons="['cancel', 'submit']"
        @submit="submit"
        :success-message="
          `${pluralize('Task', selected.length, true)}'s due date extended!`
        "
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import pluralize from "pluralize";
import {
  bulkExtendDueDate,
  employeeSearch,
} from "@/store/modules/taskAssignments";
import { wait } from "@cp/utils/promiseUtils";
import { parse } from "@cp/utils/dateUtils";

const TODAY = parse();

export default {
  mixins: [bulkExtendDueDate.mixin, bulkExtendDueDate.modalMixin],
  props: ["selected", "action"],
  data() {
    return { bulkExtendDueDate, employeeSearch };
  },
  computed: {
    buttonConf() {
      return {
        submit: {
          action: "submit",
          text: `Extend due date for ${pluralize(
            "Task",
            this.selected.length,
            true
          )}`,
          icon: "mdi-undo-variant",
          props: { color: "primary" },
        },
      };
    },
  },
  methods: {
    pluralize,
    async submit() {
      this.fields.ids.input(this.selected.map(x => x.id));
      await this.extendDueDateSubmit();
      if (this.success) {
        await wait(2000);
        this.closeModal();
      }
    },
    newDueDateAllowed(value) {
      const v = parse(value);
      return TODAY < v;
    },
  },
};
</script>
