<template>
  <v-sheet color="white" class="px-3 pb-3">
    <div class="text-bold">
      <v-icon small color="primary">mdi-chevron-right</v-icon>
      {{ formattedTime }} by <v-icon small>mdi-account-circle</v-icon>
      {{ audit.editor.editor_first_name }} {{ audit.editor.editor_last_name }}
      {{ editorNote(audit.editor_note) }}
    </div>
    <div class="mar-audit-border">
      <div v-if="audit.label" class="font-weight-bold">
        {{ audit.label }}
      </div>
      <div class="formattedChange" v-html="formattedChange" />
    </div>
  </v-sheet>
</template>

<script>
import moment from "moment";
import helpers from "@cp/mixins/helpers";
import { snakeToTitleCase } from "@cp/utils/stringUtils";
import { toExactWithTimezone, toMDY } from "@cp/utils/dateUtils";

// 2024-11-07T08:09:54.512-05:00
const dateTimeReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}-\d{2}:\d{2}$/;
const dateReg = /^\d{4}-\d{1,2}-\d{1,2}[T:.\-0-9]*$/;

function formatIt(value) {
  if (typeof value === "number") return value.toString();
  if (!value) return "<em>empty</em>";
  if (typeof value === "string") {
    if (dateTimeReg.test(value)) return toExactWithTimezone(value);
    if (dateReg.test(value)) return toMDY(value);
    return value;
  }
  if (Array.isArray(value)) {
    value = value.map(x => (x ? x : "<em>empty</em>"));
    // value 1 => value 2
    if (value.length === 2) return value.join(" &rarr; ");
    // otherwise \n
    return value.join("\n");
    // maybe in the future...
    // return value.map(formatIt).join("\n");
  }
  if (typeof value === "object") {
    return Object.keys(value)
      .map(key => {
        const title = snakeToTitleCase(key);
        let detail = formatIt(value[key]);
        if (detail.includes("\n")) {
          detail = "\n    " + detail.replace("\n", "\n    ");
        }
        return `${title}: ${detail}`;
      })
      .join("\n");
  }
  // we shouldn't ever get here... but if we do...
  console.error("Audits.vue/formatIt() couldn't format:");
  console.error(value);
}

export default {
  props: {
    audit: { type: Object, default: () => {} },
  },

  mixins: [helpers],

  computed: {
    formattedTime() {
      return moment(this.audit.created_at).format("hh:mm a");
    },

    formattedChange() {
      // if (this.audit.action === "destroy") return "Archived";
      return formatIt(this.audit.changes);
    },
  },

  methods: {
    editorNote(note) {
      if (note == null || note.trim() == "") return "";

      switch (note) {
        default:
          return note;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mar-audit-border {
  border-left: 1px solid $gray-1;
  padding-left: 8px;
  margin-left: 23px;
}

.formattedChange {
  white-space: pre;
}
</style>
