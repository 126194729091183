<template>
  <div>
    <cp-h1>
      Task Assignments
    </cp-h1>

    <v-card>
      <v-tabs
        v-model="currentIndex"
        slider-color="primary"
        background-color="#f1f1f1"
        @change="changeTab"
        fixed-tabs
        icons-and-text
      >
        <v-tabs-slider />
        <v-tab v-for="tab in tabs" :key="tab.key">
          {{ kebabToSentenceCase(tab.key) }}
          <v-icon>{{ tab.icon }}</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentIndex">
        <v-tab-item v-for="tab in tabs" :key="tab.key">
          <component
            :is="tab.component"
            :key="tab.key"
            @click:row="({ id }) => openTaskModal({ id })"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <task-assignment-status-form @closed="refreshTable" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import onWindowFocus from "@cp/mixins/onWindowFocus";
import { kebabToSentenceCase } from "@cp/utils/stringUtils";
import { setDataFromUrl, onCpEvent } from "@cp/mixins";

import {
  openTable,
  unassignedTable,
  historicalTable,
  itemFormModal,
} from "@/store/modules/taskAssignments";

const tabs = [
  {
    key: "open-tasks",
    icon: "mdi-format-list-checkbox",
    module: openTable,
    component: "open-task-assignments-table",
  },
  {
    key: "unassigned-tasks",
    icon: "mdi-account-alert-outline",
    module: unassignedTable,
    component: "unassigned-task-assignments-table",
  },
  {
    key: "task-history",
    icon: "mdi-history",
    module: historicalTable,
    component: "historical-task-assignments-table",
  },
];

export default {
  mixins: [
    onWindowFocus(
      function() {
        this.refreshTable();
      },
      { debounceTime: 10000 }
    ),
    setDataFromUrl("currentIndex", function({ query }) {
      const { tab } = query;
      if (!tab) return 0;
      const foundTabIndex = tabs.findIndex(x => x.key === tab);
      if (foundTabIndex === -1) return 0;
      return foundTabIndex;
    }),
    onCpEvent("BulkTaskModalClosed", function() {
      this.refreshTable();
    }),
  ],
  data() {
    return {
      tabs,
      currentIndex: 0,
    };
  },
  methods: {
    kebabToSentenceCase,
    ...mapActions(itemFormModal.mp, { openTaskModal: "openItemFormModal" }),
    changeTab(index) {
      const tab = tabs[index];
      this.$CpEvent.$emit("updateRoute", { query: { tab: tab.key } });
      this.refreshTable();
    },
    refreshTable() {
      const tab = tabs[this.currentIndex];
      this.$store.dispatch(tab.module.p.a.fetch);
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.refreshTable());
  },
};
</script>

<style scoped>
.v-tab {
  max-width: 500px;
}
</style>
