<template>
  <div>
    <div class="d-flex align-center pa-3">
      <cp-table-bulk-actions-menu
        v-if="selected.length"
        v-bind="bulkActionsMenuProps"
        @closed="fetchItems"
      />
      <span v-else>{{ meta.pagination.records }} Total</span>
      <v-spacer />
      <cp-filters-menu :module="table" />
    </div>
    <task-assignments-table
      v-bind="tableProps"
      v-model="tableSelected"
      v-on="listeners"
    />
  </div>
</template>

<script>
import { historicalTable } from "@/store/modules/taskAssignments";
export default {
  mixins: [historicalTable.mixin],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        ...this.tableListeners,
      };
    },
  },
};
</script>
