import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" Total: "+_vm._s(_vm.meta.pagination.records)+" ")]},proxy:true},{key:"rightOfTitle",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":_vm.openModal}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add ")],1)]},proxy:true},{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table}})]},proxy:true}])},[_vm._v(" Mentors ")]),_c(VCard,[(_vm.tableProps.items.length)?_c(VDataTable,_vm._g(_vm._b({staticClass:"clickable-rows",on:{"click:row":function (ref) {
	var id = ref.id;

	return _vm.$router.push({ name: 'mentorDetail', params: { id: id } });
}}},'v-data-table',_vm.tableProps,false),_vm.tableListeners)):_c('no-data-screen',{attrs:{"text":_vm.noDataText,"noun":_vm.noun},on:{"click":function($event){return _vm.openModal()}}})],1),_c('new-mentor')],1)}
var staticRenderFns = []

export { render, staticRenderFns }