<template>
  <cp-loading :loading="loading">
    <cp-h1>
      Mentor Details

      <template #subtitle>
        <router-link :to="{ name: 'mentors' }">Mentors</router-link>
        <span class="mx-2">/</span>
        {{ item.full_name }}
      </template>
    </cp-h1>

    <v-card class="mt-1">
      <v-card-text>
        <v-row class="summaryRow align-center">
          <v-col class="flex-grow-0">
            <div style="width: 100px">
              <cp-profile-avatar :profile="item" />
            </div>
          </v-col>
          <v-col>
            <table class="summary-table">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{{ item.full_name }}</td>
                </tr>
                <tr>
                  <td>Position</td>
                  <td>{{ item.position }}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{{ item.location }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <a v-if="item.email" :href="`mailto:${item.email}`">{{
                      item.email
                    }}</a>
                    <span v-else>missing</span>
                  </td>
                </tr>
                <tr>
                  <td>Cell</td>
                  <td>
                    <a v-if="item.email" :href="`tel:${item.cell_phone}`">{{
                      item.cell_phone
                    }}</a>
                    <span v-else>missing</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <cp-h1>
      Mentees
      <template #subtitle> {{ item.mentees.length }} total </template>
      <template #rightOfTitle>
        <v-btn @click="openModal" color="primary" small>
          <v-icon left>mdi-plus</v-icon>
          Add
        </v-btn>
      </template>
    </cp-h1>

    <new-mentee :mentor="item" />

    <v-card>
      <v-data-table v-bind="menteeTableProps">
        <template #item.full_name="{item}">
          {{ item.first_name }} {{ item.last_name }}
        </template>
        <template #item.start_date="{value}">{{ toMDY(value) }}</template>
        <template #item.contact="{item}">
          <span v-if="item.email" :title="item.email">
            <a :href="`mailto:${item.email}`">
              <v-icon color="primary">mdi-email-outline</v-icon>
            </a>
          </span>
          <span v-if="item.cell_phone" :title="item.cell_phone">
            <a :href="`tel:${item.cell_phone}`">
              <v-icon color="primary">mdi-phone-outline</v-icon>
            </a>
          </span>
        </template>
      </v-data-table>
    </v-card>

    <cp-h1>
      Mentor Tasks
      <template #subtitle>{{ meta.pagination.records }} total</template>
      <template #rightOfTitle>
        <cp-table-bulk-actions-menu
          v-if="selected.length"
          v-bind="bulkActionsMenuProps"
          @closed="fetchItems"
        />
      </template>
      <template #right>
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <v-card>
      <task-assignments-table
        v-bind="tableProps"
        v-model="tableSelected"
        v-on="tableListeners"
        @click:row="({ id }) => openTaskModal({ id })"
      />
    </v-card>

    <task-assignment-status-form @closed="fetchItems" />
  </cp-loading>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import { toMDY } from "@cp/utils/dateUtils";

import { itemFormModal } from "@/store/modules/taskAssignments";
import { item, newMenteeModal, mentorTasks } from "@/store/modules/mentors";

export default {
  mixins: [newMenteeModal.modalMixin, mentorTasks.mixin],
  computed: {
    ...mapState(item.mp, ["item"]),
    ...mapState(item.mp, {
      touched: "itemTouched",
      loading: "itemLoading",
      submitting: "itemSubmitting",
    }),
    menteeTableProps() {
      return {
        items: this.item.mentees,
        headers: [
          { text: "Name", value: "full_name" },
          { text: "Start Date", value: "start_date" },
          { text: "Location", value: "location.name" },
          { text: "Position", value: "position.name" },
          { text: "Contact", value: "contact" },
        ],
        itemsPerPage: 5,
      };
    },
  },
  methods: {
    toMDY,
    ...mapMutations(item.mp, ["resetItem"]),
    ...mapActions(item.mp, ["fetchItem", "update"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions(itemFormModal.mp, { openTaskModal: "openItemFormModal" }),

    ...mapMutations(mentorTasks.mp, ["resetItems"]),
    ...mapActions(mentorTasks.mp, { fetchTasks: "fetchItems" }),
    ...mapActions(mentorTasks.mp, { fetchTasks: "fetchItems" }),

    confirmDelete() {
      this.confirm({
        verb: "delete",
        noun: item.noun,
        alertText: `This will delete the ${item.noun} permanently!`,
        action: item.p.a.delete,
      });
    },

    updateIfSuccess({ success } = {}) {
      if (success) {
        this.fetchTasks();
      }
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.resetItem();
      await this.fetchItem({ id });
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => vm.resetItems());
  },
};
</script>
