import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,_vm._g(_vm._b({staticClass:"clickable-rows",attrs:{"no-data-text":("0 tasks " + (_vm.filterCount ? ("match those " + _vm.filterCount + " filters") : 'found')),"disable-pagination":""},on:{"click:row":function (item) { return _vm.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.completion",fn:function(ref){
      var item = ref.item;
return [_c('cp-explain',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('task-progress-cell',_vm._b({},'task-progress-cell',{ item: item },false))],1)]}}],null,true)},[_c('task-status-summary-cell',_vm._b({},'task-status-summary-cell',{ item: item },false))],1)]}},{key:"item.explanation",fn:function(ref){
      var item = ref.item;
return [_c('task-status-summary-cell',_vm._b({},'task-status-summary-cell',{ item: item },false))]}},{key:"item.task.title",fn:function(ref){
      var item = ref.item;
return [_c('cp-explain',{attrs:{"close-on-content-click":false,"max-width":"550","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.task.title)+" ")])]}}],null,true)},[_c(VCardTitle,[_vm._v(_vm._s(item.task.title))]),_c(VCardText,[_c('p',[_c('strong',[_vm._v("Description:")])]),_c('div',{staticClass:"displayHTML",domProps:{"innerHTML":_vm._s(item.task.description)}}),_c('p'),_c('p',[_c('strong',[_vm._v("Schedule:")]),_vm._v(" "+_vm._s(item.schedule_text)+" ")]),_c('p',[_c('strong',[_vm._v(" Enrolled by "+_vm._s(_vm.pluralize("plan", item.plans.length))+": ")]),_vm._v(" "+_vm._s(item.plans.map(function (x) { return x.title; }).join(", "))+" ")]),_c('p',[_c('strong',[_vm._v("Assigned to:")]),_vm._v(" "+_vm._s(_vm.snakeToTitleCase(item.assignment_type))+" ")])])],1)]}},{key:"item.notes",fn:function(ref){
      var value = ref.value;
return [(value && value.length)?_c('cp-explain',{attrs:{"root-slot":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-message")])],1)]}}],null,true)},[_c(VCard,[_c(VDataTable,{attrs:{"items":value,"headers":_vm.notesHeaders,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.toMDY(value))+" ")]}}],null,true)})],1)],1):_vm._e()]}},{key:"item.due_by_date",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.toMDY(value))+" ")]}},{key:"item.completed_at",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.toMDY(value))+" ")]}},{key:"item.on_behalf_of",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.on_behalf_of.full_name)+" ")]}},{key:"item.completed_by",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.completed_by_name)+" ")]}},{key:"item.assignees",fn:function(ref){
      var value = ref.value;
return [(value.length)?_c('div',_vm._l((value),function(assignee){return _c('div',{key:assignee.id,staticClass:"text-no-wrap"},[_vm._v(_vm._s(assignee.name))])}),0):_c('span',{staticClass:"error--text text-no-wrap"},[_vm._v("No Assignees")])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }