import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-toolbar-module-modal',_vm._g({attrs:{"modalConf":_vm.modalConf,"width":"680"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Edit "+_vm._s(((_vm.item.first_name) + " " + (_vm.item.last_name)))+"'s info")]},proxy:true}])},_vm.$listeners),[_c('cp-loading',{attrs:{"loading":_vm.loading}},[_c(VRow,{staticClass:"dense-vertical"},[_c(VCol,{attrs:{"cols":"6"}},[_c('cp-search-autocomplete',_vm._b({attrs:{"form":_vm.form,"searchModule":_vm.locationsSearch,"name":"location_id","label":"Location","initialValue":_vm.item.location_id
              ? {
                  value: _vm.item.location_id,
                  label: _vm.item.location,
                }
              : null,"outlined":"","dense":""},on:{"input":_vm.fields.location_id.input}},'cp-search-autocomplete',_vm.fields.location_id.attrs,false))],1),_c(VCol,{attrs:{"cols":"6"}},[_c('cp-search-autocomplete',_vm._b({attrs:{"form":_vm.form,"searchModule":_vm.positionsSearch,"name":"position_id","label":"Position","initialValue":_vm.item.position_id
              ? {
                  value: _vm.item.position_id,
                  label: _vm.item.position,
                }
              : null,"outlined":"","dense":""},on:{"input":_vm.fields.position_id.input}},'cp-search-autocomplete',_vm.fields.position_id.attrs,false))],1),_c(VCol,{attrs:{"cols":"6"}},[_c('cp-date-picker',_vm._b({attrs:{"defaultDaysInFuture":false,"label":"Start Date","outlined":"","dense":""},on:{"input":_vm.fields.start_date.input}},'cp-date-picker',_vm.fields.start_date.attrs,false))],1)],1),_c('cp-unhandled-errors',{attrs:{"formConf":_vm.formConf}}),_c('cp-form-modal-success-or-buttons',{attrs:{"modalConf":_vm.modalConf,"formConf":_vm.formConf,"buttons":['cancel', 'update'],"noun":"Employee","success-message":"Employee updated!"},on:{"submit":_vm.submit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }