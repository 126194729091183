<template>
  <span v-if="item.user_task_progress_status_id === 1">
    Scheduled for {{ dates.alert }} (will alert in
    {{ pluralize("day", daysFrom.alert, true) }})
  </span>
  <span v-else-if="item.user_task_progress_status_id === 2">
    Pending (due
    <span v-if="daysFrom.due > 0">
      in {{ pluralize("day", daysFrom.due, true) }}
    </span>
    <span v-else>today</span>)
  </span>
  <span v-else-if="item.user_task_progress_status_id === 3">
    <v-chip v-bind="PS.pastDue.chipProps" small class="mr-2">
      <v-icon small left>{{ PS.pastDue.icon }}</v-icon>
      {{ PS.pastDue.title }}
    </v-chip>
    <span
      v-if="daysFrom.due < 0"
      :style="{ color: PS.pastDue.color }"
      class="font-weight-black"
      >({{ pluralize("day", -daysFrom.due, true) }} late)</span
    >
    <!-- Just in case Michael's script hasn't run yet, has an error  -->
    <span v-else>(due today)</span>
  </span>
  <span v-else-if="item.user_task_progress_status_id === 4">
    <span v-if="item.user_task_compliance_status_id === 1">
      Completed <span class="success--text">on time</span> by
      {{ item.completed_by_name }}
    </span>
    <span v-if="item.user_task_compliance_status_id === 2">
      Completed
      <span class="error--text">late</span> by {{ item.completed_by_name }}
    </span>
    <span v-if="item.user_task_compliance_status_id === 3">
      Marked
      <v-chip :color="CS.cantComplete.color" text-color="white" small>
        <v-icon small left>{{ CS.cantComplete.icon }}</v-icon>
        Can't Complete
      </v-chip>
      by {{ item.completed_by_name }} on {{ dates.completed }}
    </span>
    <span v-if="item.user_task_compliance_status_id === 4">
      <v-chip :color="CS.abandoned.color" text-color="white" small>
        <v-icon small left>{{ CS.abandoned.icon }}</v-icon>
        Abandoned
      </v-chip>
      on {{ dates.abandon }}
    </span>
    <span v-if="item.user_task_compliance_status_id === 5">
      Marked
      <v-chip :color="CS.canceled.color" text-color="white" small>
        <v-icon small left>{{ CS.canceled.icon }}</v-icon>
        Canceled
      </v-chip>
      <!-- If Michael's spelling were a function, it would return 'undefind' -->
      {{ item.admin_note ? item.admin_note.replace(/^Cancell*ed /, "") : "" }}
      on
      {{ dates.adminNote }}
    </span>
  </span>
</template>

<script>
import pluralize from "pluralize";
import { parse, toMDY } from "carrot-patch-v2/src/utils/dateUtils";

import {
  progressStatuses as PS,
  complianceStatuses as CS,
} from "@/store/modules/tasks";

const d = dateStr => (dateStr ? toMDY(dateStr) : "");

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { PS, CS };
  },
  computed: {
    dates() {
      return {
        abandon: d(this.item.abandon_date),
        adminNote: d(this.item.admin_note_created_at),
        completed: d(this.item.completed_at),
        alert: d(this.item.alert_date),
      };
    },
    daysFrom() {
      const today = parse().startOf("day");
      const alert = parse(this.item.alert_date).startOf("day");
      const due = parse(this.item.due_by_date).startOf("day");
      return {
        alert: alert.diff(today, "days"),
        due: due.diff(today, "days"),
      };
    },
  },
  methods: { pluralize },
};
</script>
