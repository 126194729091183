<template>
  <div>
    <cp-h1>
      {{ constants.PLANS }}
      <template #subtitle>Total: {{ meta.pagination.records }}</template>
      <template #rightOfTitle>
        <v-menu>
          <template #activator="{on, attrs}">
            <v-btn v-on="on" v-bind="attrs" color="primary" dark small>
              <v-icon left class="mr-1">mdi-plus</v-icon>
              Add
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item @click="openModal">
                <v-list-item-icon>
                  <v-icon>mdi-file-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Create an empty plan</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openTemplateModal">
                <v-list-item-icon>
                  <v-icon>mdi-file-document-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Start with a template</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
      <template #right>
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <create-plan-from-template @success="id => openItemDetailModal({ id })" />

    <v-card class="card-listing">
      <v-data-table
        v-bind="tableProps"
        v-on="tableListeners"
        @click:row="item => openItemDetailModal({ id: item.id })"
        class="clickable-rows"
        hide-default-footer
      >
        <template #header.plan_type_id="{header}">
          <cp-table-header-filterable
            :header="header"
            :table="table"
            filter-key="plan_types"
          />
        </template>

        <template #no-data>
          <no-data-screen
            :text="noDataText"
            :noun="noun"
            @click="openModal()"
          />
        </template>

        <template #item.is_core="{value}">
          <core-indicator v-if="value" class="ml-4">
            <div>
              <h3>This is a core plan.</h3>
              It applies to all locations and positions.
            </div>
          </core-indicator>
        </template>

        <template #item.title="{item}">
          {{ item.title }}
          <cp-status-chip
            v-if="item.plan_status_id === 3"
            label
            dark
            status="draft"
            class="ml-2"
          >
            Draft
          </cp-status-chip>
        </template>

        <template v-slot:item.updated_at="{ value }">
          {{ toMDY(value) }}
        </template>

        <template v-slot:item.plan_type_id="{ item }">
          {{ item.plan_type.label }}
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        v-on="paginationListeners"
      />
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { onCpEvent } from "@cp/mixins";
import { toMDY } from "@cp/utils/dateUtils.js";
import { genericConstants } from "../constants/genericConstants";

import {
  itemDetailModal,
  itemFormModal,
  itemsTable,
  templates,
} from "@/store/modules/plans";

export default {
  name: "Plans",
  mixins: [
    itemsTable.mixin,
    itemsTable.filtersMixin,
    itemFormModal.modalMixin,
    onCpEvent(itemFormModal.keys.modalClosedEvent, function() {
      this.fetchItems();
    }),
    onCpEvent(itemDetailModal.keys.modalClosedEvent, function() {
      this.fetchItems();
    }),
  ],

  data() {
    return {
      constants: genericConstants,
    };
  },
  methods: {
    toMDY,
    ...mapActions("plans", [
      "fetchItem",
      "openPlanDetailModal",
      "updateSearch",
      "openItemDetailModal",
    ]),
    ...mapActions("plans", {
      fetchPlans: "fetch",
    }),
    async getPlanDetail(id) {
      await this.openPlanDetailModal(id);
      this.planDetailModalFlag = true;
    },
    editCreateModalClosed({ created, id } = {}) {
      this.openCreateEditPlanModal = false;
      if (created) this.getPlanDetail(id);
    },
    openTemplateModal() {
      this.$store.dispatch(templates.p.a.modalOpen);
    },
  },
};
</script>
