import { ItemsTable } from "@cp/store/mixins";

export const dashboard = new ItemsTable({
  module: "dashboard",
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/dashboard`,
  noun: "Employee",
  filters: [
    "q_text",
    {
      type: "select",
      key: "introduce_status",
      label: "Status",
      items: [
        { label: "Onboarding", value: "onboarding" },
        { label: "Offboarding", value: "offboarding" },
      ],
      initialValue: "onboarding",
    },
  ],
  initialMeta: {
    additional_meta: {},
    filter_options: { general: [] },
    pagination: { current: 1, records: 0, last: 0, next: 0 },
    summary_object: {
      focus_items: [],
      compliance: {
        client_id: "",
        users_task_count: 0,
        task_count: 0,
        compliant: 0,
        non_compliant: 0,
        _ontime: 0,
        _late: 0,
        _cant_complete: 0,
        _abandoned: 0,
        _canceled: 0,
        compliant_percentage: "0",
        id: null,
      },
      compliance_chart: {
        title: "",
        icon: "",
        icon_color: "",
        stats: [
          {
            type: "pie",
            title: "",
            icon: "",
            icon_color: "",
            text_color: "",
            value: "",
            data: {
              label: "",
              values: [],
            },
            click_args: {},
          },
        ],
      },
    },
  },
});
dashboard.add({
  getters: {
    itemsTableHeaders(state) {
      const cell2 =
        state.itemsFilterParams.introduce_status === "onboarding"
          ? {
              text: "Start Date",
              value: "start_date",
            }
          : {
              text: "Last Day",
              value: "last_archived_at",
            };
      return [
        {
          text: "Employee",
          value: "full_name",
        },
        cell2,
        {
          text: "%",
          value: "progress.complete_percentage",
          class: "text-nowrap",
        },
        {
          text: "Est. Completed Date",
          value: "estimated_plan_completion_date",
          class: "text-nowrap",
        },
        {
          text: "Job Title",
          value: "position",
        },
        {
          text: "Location",
          value: "location",
        },
      ];
    },
  },
});

window.$dashboard = dashboard;

export default dashboard.toVuex;
