import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v("Total: "+_vm._s(_vm.meta.pagination.records))]},proxy:true},{key:"rightOfTitle",fn:function(){return [_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-1",attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add ")],1)]}}])},[_c(VCard,[_c(VList,[_c(VListItem,{on:{"click":_vm.openModal}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-file-plus-outline")])],1),_c(VListItemTitle,[_vm._v("Create an empty plan")])],1),_c(VListItem,{on:{"click":_vm.openTemplateModal}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-file-document-plus-outline")])],1),_c(VListItemTitle,[_vm._v("Start with a template")])],1)],1)],1)],1)]},proxy:true},{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.constants.PLANS)+" ")]),_c('create-plan-from-template',{on:{"success":function (id) { return _vm.openItemDetailModal({ id: id }); }}}),_c(VCard,{staticClass:"card-listing"},[_c(VDataTable,_vm._g(_vm._b({staticClass:"clickable-rows",attrs:{"hide-default-footer":""},on:{"click:row":function (item) { return _vm.openItemDetailModal({ id: item.id }); }},scopedSlots:_vm._u([{key:"header.plan_type_id",fn:function(ref){
var header = ref.header;
return [_c('cp-table-header-filterable',{attrs:{"header":header,"table":_vm.table,"filter-key":"plan_types"}})]}},{key:"no-data",fn:function(){return [_c('no-data-screen',{attrs:{"text":_vm.noDataText,"noun":_vm.noun},on:{"click":function($event){return _vm.openModal()}}})]},proxy:true},{key:"item.is_core",fn:function(ref){
var value = ref.value;
return [(value)?_c('core-indicator',{staticClass:"ml-4"},[_c('div',[_c('h3',[_vm._v("This is a core plan.")]),_vm._v(" It applies to all locations and positions. ")])]):_vm._e()]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" "),(item.plan_status_id === 3)?_c('cp-status-chip',{staticClass:"ml-2",attrs:{"label":"","dark":"","status":"draft"}},[_vm._v(" Draft ")]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.toMDY(value))+" ")]}},{key:"item.plan_type_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan_type.label)+" ")]}}])},'v-data-table',_vm.tableProps,false),_vm.tableListeners)),(!_vm.noData)?_c(VPagination,_vm._g(_vm._b({staticClass:"mt-2 text-center"},'v-pagination',_vm.paginationProps,false),_vm.paginationListeners)):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }