import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',[_vm._v(" Task Assignments ")]),_c(VCard,[_c(VTabs,{attrs:{"slider-color":"primary","background-color":"#f1f1f1","fixed-tabs":"","icons-and-text":""},on:{"change":_vm.changeTab},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},[_c(VTabsSlider),_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.key},[_vm._v(" "+_vm._s(_vm.kebabToSentenceCase(tab.key))+" "),_c(VIcon,[_vm._v(_vm._s(tab.icon))])],1)})],2),_c(VTabsItems,{model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},_vm._l((_vm.tabs),function(tab){return _c(VTabItem,{key:tab.key},[_c(tab.component,{key:tab.key,tag:"component",on:{"click:row":function (ref) {
	var id = ref.id;

	return _vm.openTaskModal({ id: id });
}}})],1)}),1)],1),_c('task-assignment-status-form',{on:{"closed":_vm.refreshTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }