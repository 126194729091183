<template>
  <div>
    <cp-h1>
      Employee Details

      <template #subtitle>
        <cp-breadcrumbs v-bind="breadcrumbProps" />
      </template>
    </cp-h1>
    <v-card class="modal-content-card mcc-details">
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <cp-profile-avatar :profile="detail" />
          </v-col>
          <v-col cols="7">
            <div>
              Name:
              <strong>{{ detail.full_name }}</strong>
            </div>
            <div>
              Position:
              <strong>{{ detail.position }}</strong>
            </div>
            <div>Employee ID: <strong>0000</strong></div>
            <div>
              Start Date:
              <strong>{{ detail.start_date }}</strong>
            </div>
            <div>
              Location:
              <strong>{{ detail.location }}</strong>
            </div>
            <div>
              Personal Email:
              <strong
                ><a v-bind:href="'mailto:' + detail.email">{{
                  detail.email
                }}</a></strong
              >
            </div>
            <div>
              Cell:
              <strong>{{ detail.cell_phone || "NA" }}</strong>
            </div>
            <v-divider />
            <div>
              Mentors:
              <strong v-if="!detail.mentors || !detail.mentors.length">
                None
              </strong>
              <span v-else>
                <v-chip
                  v-for="mentor in detail.mentors"
                  :key="mentor.value"
                  class="ma-2 pl-2 py-0 mentor-chip"
                  small
                  closable
                >
                  {{ mentor.label }}
                  <v-icon @click="removeMentor(mentor.value)" right small>
                    mdi-close-circle
                  </v-icon>
                </v-chip>
              </span>
            </div>
          </v-col>
          <v-col cols="3" class="text-right d-flex flex-column align-end">
            <v-btn @click="openEditEmployeeModal" color="primary" text>
              <v-icon left>mdi-account-edit</v-icon>
              Update Information
            </v-btn>
            <v-btn @click="openAddMentorModal" color="primary" text>
              <v-icon left>mdi-account-multiple-plus-outline</v-icon>
              Add a new mentor
            </v-btn>
            <v-btn @click="enroll" color="primary" text>
              <v-icon left>mdi-format-list-group-plus</v-icon>
              Enroll in a new plan
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="1">
            <tasks-progress-pie :tasks="tasks" />
          </v-col>
          <v-col cols="11" class="align-self-start">
            <tasks-status-bar
              :tasks="tasks"
              @filter="obj => setFilter(obj, true)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <cp-h1>
      Plans
      <v-btn @click="enroll" class="ml-5" color="primary" small>
        <v-icon left>mdi-plus</v-icon>
        Enroll in plan
      </v-btn>
    </cp-h1>
    <v-card class="modal-conetnet-card">
      <v-list class="py-0">
        <template v-for="(enrollment, i) in enrollments">
          <v-divider v-if="i" :key="`divider-${enrollment.id}`" />
          <v-list-item
            :key="enrollment.id"
            :input-value="filter.plan === enrollment.plan_id"
            class="py-2"
          >
            <v-row class="align-center">
              <v-col cols="auto" style="width: 145px">
                <settings-menu :items="enrollmentActions(enrollment)">
                  <template #activator="{on}">
                    <v-chip
                      v-on="on"
                      small
                      outlined
                      color="primary"
                      class="pl-0"
                    >
                      <v-avatar>
                        <v-icon small>
                          {{ enrollmentStatus(enrollment).icon }}
                        </v-icon>
                      </v-avatar>
                      <span>
                        {{ enrollmentStatus(enrollment).label }}
                      </span>
                      <v-icon right class="ml-1" small>mdi-chevron-down</v-icon>
                    </v-chip>
                  </template>
                </settings-menu>
              </v-col>
              <v-col cols="4">
                {{ enrollment.plan.title }}
              </v-col>
              <v-col cols="auto">
                <tasks-progress-pie :tasks="enrollment.tasks" />
              </v-col>
              <v-col class="align-self-start">
                <tasks-status-bar
                  :tasks="enrollment.tasks"
                  @filter="
                    obj => setFilter({ ...obj, plan: enrollment.plan_id })
                  "
                />
              </v-col>
              <v-col v-if="enrollments.length > 1" cols="auto" align="right">
                <v-btn
                  small
                  @click="toggleFilter('plan', enrollment.plan_id)"
                  :color="filter.plan === enrollment.plan_id ? '' : 'primary'"
                >
                  <v-icon left>
                    {{
                      filter.plan === enrollment.plan_id
                        ? "mdi-filter-off"
                        : "mdi-filter"
                    }}
                  </v-icon>
                  Filter tasks
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
    <cp-h1 right-class="align-center">
      Tasks List
      <template #subtitle>
        <span v-if="filterCount">
          showing {{ filtered.tasks.length }} / {{ tasks.length }}
        </span>
        <span v-else> {{ tasks.length }} total </span>
      </template>
      <template #right>
        <div class="taskStatusFilters d-flex align-center">
          <v-text-field
            v-model="search"
            placeholder="Search for task"
            class="mr-3"
            outlined
            hide-details
            dense
          >
            <template #prepend-inner>
              <v-icon>mdi-magnify</v-icon>
              <v-btn
                v-if="filterCount"
                class="mr-3 my-0"
                rounded
                small
                @click="setFilter({})"
              >
                Clear Filters
                <v-icon right>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <v-menu
            v-if="
              canFilterBy.plan ||
                canFilterBy.progressStatus ||
                canFilterBy.complianceStatus
            "
            :close-on-content-click="false"
          >
            <template #activator="{on, attrs}">
              <v-btn v-bind="attrs" v-on="on" color="primary" small>
                <v-icon left>mdi-filter</v-icon>
                <v-badge
                  v-if="filterCount"
                  :content="filterCount"
                  class="buttonCount"
                >
                  Filter
                </v-badge>
                <span v-else>Filter</span>
              </v-btn>
            </template>

            <v-list dense>
              <template v-if="canFilterBy.plan">
                <v-subheader>Plans</v-subheader>
                <v-list-item
                  v-for="enrollment in enrollments"
                  :key="`plan-${enrollment.plan_id}`"
                  @click="toggleFilter('plan', enrollment.plan_id)"
                  :input-value="filter.plan === enrollment.plan_id"
                >
                  <v-list-item-icon>
                    <v-icon v-if="filter.plan === enrollment.plan_id">
                      mdi-filter-off-outline
                    </v-icon>
                    <v-icon v-else>
                      mdi-clipboard-text-multiple
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ enrollment.plan.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>

              <v-subheader>Progress</v-subheader>
              <v-list-item
                v-for="status in filtered.progressStatuses"
                :key="`ps-${status.id}`"
                @click="toggleFilter('progressStatus', status.id)"
                :input-value="filter.progressStatus === status.id"
              >
                <v-list-item-icon>
                  <v-icon v-if="filter.status === status.id">
                    mdi-filter-off-outline
                  </v-icon>
                  <v-icon v-else>
                    mdi-filter-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ status.title }}
                </v-list-item-title>
              </v-list-item>
              <template v-if="canFilterBy.complianceStatus">
                <v-subheader>Status</v-subheader>
                <v-list-item
                  v-for="status in filtered.complianceStatuses"
                  :key="`cs-${status.id}`"
                  @click="toggleFilter('complianceStatus', status.id)"
                  :input-value="filter.complianceStatus === status.id"
                >
                  <v-list-item-icon>
                    <v-icon v-if="filter.status === status.id">
                      mdi-filter-off-outline
                    </v-icon>
                    <v-icon v-else>
                      mdi-filter-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ status.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </div>
      </template>
    </cp-h1>
    <v-card class="modal-content-card" elevation="20">
      <v-data-table
        @click:row="openTaskModal"
        :headers="headers"
        :items="filtered.tasks"
        :loading="loading"
        :search="search"
        :no-data-text="
          `0 tasks ${
            filterCount ? `match those ${filterCount} filters` : 'found'
          }`
        "
        class="clickable-rows"
        hide-default-footer
        disable-pagination
      >
        <template #item.completion="{ item }">
          <task-progress-cell v-bind="{ item }" />
        </template>

        <template v-slot:item.explanation="{ item }">
          <task-status-summary-cell v-bind="{ item }" />
        </template>

        <template v-slot:item.task.title="{ item }">
          <cp-explain :close-on-content-click="false" max-width="550" offset-x>
            <template #activator="{on}">
              <span v-on="on">
                {{ item.task.title }}
              </span>
            </template>
            <v-card-title>{{ item.task.title }}</v-card-title>
            <v-card-text>
              <div>
                <strong>{{ constants.DESCRIPTION_HEADING }}</strong>
                <div v-html="item.task.description" class="displayHTML"></div>
              </div>

              <div>
                <strong>{{ constants.SCHEDULE }}</strong>
                {{ item.schedule_text }}
              </div>
              <div>
                <strong>
                  Enrolled by
                  {{ pluralize("plan", item.plans.length) }}:
                </strong>
                {{ item.plans.map(x => x.title).join(", ") }}
              </div>
              <div>
                <strong>Assigned to:</strong>
                {{ snakeToTitleCase(item.assignment_type) }}
              </div>
            </v-card-text>
          </cp-explain>
        </template>

        <template v-slot:item.notes="{ value }">
          <cp-explain v-if="value && value.length" root-slot>
            <template #activator="{on, attrs}">
              <v-btn v-bind="attrs" v-on="on" icon color="primary">
                <v-icon>mdi-message</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-data-table
                :items="value"
                :headers="notesHeaders"
                hide-default-footer
                dense
              >
                <template #item.created_at="{ value }">
                  {{ toMDY(value) }}
                </template>
              </v-data-table>
            </v-card>
          </cp-explain>
        </template>

        <template #item.due_by_date="{value}">
          {{ toMDY(value) }}
        </template>
      </v-data-table>
    </v-card>
    <div style="height:10vh;"></div>
    <edit-employee-modal @closed="updateIfSuccess" />
    <task-status-form @closed="updateIfSuccess" />
    <add-mentor-to-employee @closed="updateIfSuccess" :id="detail.id" />
    <enroll-form @closed="updateIfSuccess" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import cloneDeep from "clone-deep";
import pluralize from "pluralize";

import { Client } from "@cp/lib";
import { toMDY } from "@cp/utils/dateUtils";
import { compact, unique } from "@cp/utils/arrayUtils";
import { snakeToTitleCase } from "@cp/utils/stringUtils";
import { pick, filterObject } from "@cp/utils/objectUtils";

import { genericConstants } from "../constants/genericConstants";
import {
  enrollmentActions,
  enrollmentStatusObj,
} from "@/store/modules/enrollments";
import {
  progressStatusesOrder as PSO,
  complianceStatusesOrder as CSO,
} from "@/store/modules/tasks";
import { completedTypes, completedTypeObj } from "@/store/modules/reports";
import { addMentorModal, editEmployeeModal } from "@/store/modules/employees";

const marigoldApi = new Client({
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`,
});

const headers = [
  {
    text: "✓",
    sortable: false,
    value: "completion",
    width: 60,
  },
  {
    text: "Task",
    value: "task.title",
    sortable: true,
    cellClass: "clickable-cell",
  },
  {
    text: "Details",
    sortable: false,
    value: "explanation",
    cellClass: "text-no-wrap",
    width: "40%",
  },
  {
    text: "Due",
    value: "due_by_date",
    cellClass: "text-no-wrap",
    width: 80,
    sortable: true,
  },
  {
    text: "Notes",
    value: "notes",
    sortable: false,
    width: 80,
  },
];

const notesHeaders = [
  {
    text: "Employee",
    value: "user",
  },
  {
    text: "Date",
    value: "created_at",
  },
  {
    text: "Note",
    value: "note",
  },
];

const DEFAULT_FILTER = {
  plan: "",
  progressStatus: 0,
  complianceStatus: 0,
};

export default {
  name: "EmployeeStatus",
  data() {
    return {
      completedTypes,
      completedTypeObj,
      constants: genericConstants,
      headers,
      notesHeaders,
      search: "",

      filter: cloneDeep(DEFAULT_FILTER),
    };
  },
  computed: {
    ...mapState("employees", {
      detail: "item",
    }),
    ...mapState("employees/userTasks", {
      tasks: "items",
    }),
    ...mapGetters("employees", {
      enrollments: "employeeEnrollments",
    }),
    loading() {
      return this.detailLoading || this.reportLoading;
    },
    breadcrumbProps() {
      return {
        items: [
          {
            text: "Employees",
            to: { name: "EmployeesReports" },
            exact: true,
          },
          {
            text: this.detail.full_name,
          },
        ],
      };
    },

    filtered() {
      let tasks = this.tasks;

      if (this.filter.plan)
        tasks = tasks.filter(t =>
          t.plans.map(x => x.id).includes(this.filter.plan)
        );

      if (this.search)
        tasks = tasks.filter(t =>
          t.task.title.toLowerCase().includes(this.search.toLowerCase())
        );

      const psIds = compact(
        unique(tasks.map(x => x.user_task_progress_status_id))
      );
      const csIds = compact(
        unique(tasks.map(x => x.user_task_compliance_status_id))
      );

      const progressStatuses = PSO.filter(x => psIds.includes(x.id));
      const complianceStatuses = CSO.filter(x => csIds.includes(x.id));

      if (this.filter.progressStatus)
        tasks = tasks.filter(
          t => t.user_task_progress_status_id === this.filter.progressStatus
        );

      if (this.filter.complianceStatus)
        tasks = tasks.filter(
          t => t.user_task_compliance_status_id === this.filter.complianceStatus
        );

      return { tasks, progressStatuses, complianceStatuses };
    },
    canFilterBy() {
      return {
        plan: this.enrollments.length > 1,
        progressStatus: true,
        complianceStatus: this.filtered.complianceStatuses.length,
      };
    },
    filterCount() {
      return [
        this.filter.plan,
        this.filter.progressStatus,
        this.filter.complianceStatus,
      ].filter(x => x).length;
    },
  },
  methods: {
    toMDY,
    pluralize,
    snakeToTitleCase,
    ...mapActions("employees", ["fetchItem"]),
    ...mapActions("taskStatusForm", ["openItemFormModal"]),
    ...mapActions("plans", ["openPlanDetailModal"]),
    ...mapActions("enrollments", ["openEnrollModal"]),
    openTaskModal(item) {
      this.openItemFormModal({ id: item.id });
    },
    fetch() {
      this.fetchItem({ id: this.$route.params.id });
    },
    updateIfSuccess({ success } = {}) {
      if (success) {
        this.fetch();
      }
    },
    openEditTaskStatus(taskId) {
      this.taskId = taskId;
      this.openEditTaskStatusModal = true;
    },
    openAddMentorModal() {
      this.$store.dispatch(addMentorModal.p.a.modalOpen);
    },
    openEditEmployeeModal() {
      this.$store.dispatch(editEmployeeModal.p.a.modalOpen, {
        id: this.$route.params.id,
      });
    },
    async removeMentor(id) {
      const payload = {
        mentees: [{ mentee_id: this.detail.id, mentor_ids: [id] }],
      };
      const response = await marigoldApi.put("users/remove_mentors", payload);
      this.$CpEvent.$emit("snackAlert", {
        message: "Mentor Removed!",
        color: "success",
      });
      this.fetch();
    },
    setFilter(obj) {
      const newValue = pick(obj, Object.keys(filterObject(this.canFilterBy)));
      Object.assign(this.filter, DEFAULT_FILTER, newValue);
    },
    toggleFilter(k, v) {
      if (!this.canFilterBy[k]) return;
      if (this.filter[k] === v) {
        this.filter[k] = DEFAULT_FILTER[k];
      } else {
        this.filter[k] = v;
      }
    },
    enrollmentStatus({ enrollment_status_id }) {
      return enrollmentStatusObj[enrollment_status_id] || {};
    },
    enrollmentActions({ id, admin_actions = [] }) {
      const $vm = this;
      return enrollmentActions
        .filter(x => admin_actions.includes(x.action))
        .map(option => {
          const { text, action, icon, explanation, props } = option;

          function click() {
            $vm.$store
              .dispatch(`enrollments/${action}`, { id })
              .then(() => $vm.updateIfSuccess({ success: true }));
          }

          return {
            text,
            icon,
            explanation,
            props,
            click,
          };
        });
    },
    enroll() {
      const exclude_plan_ids = this.enrollments.map(x => x.plan_id);
      this.openEnrollModal({ exclude_plan_ids });
    },
  },
  mounted() {
    this.fetch();
  },
  beforeRouteUpdate(to, from, next) {
    this.fetch();
    next();
  },
};
</script>

<style lang="scss" scoped>
.status-prefix {
  display: inline-block;
  width: 90px;
}

.buttonCount::v-deep .v-badge__badge {
  box-shadow: inset 0 0 0 1.5px #fff, 0 0 4px rgba(0, 0, 0, 0.8);
}

.v-list-item--active {
  background-color: lighten($primary, 55%);
}

.taskStatusFilters::v-deep {
  .v-input--hide-details.v-text-field--enclosed.v-input--dense {
    background: #fff;
    .v-input__prepend-inner {
      margin-top: 6px;
    }
  }
}

.mentor-chip {
  .v-icon:hover {
    color: $error;
  }
}
</style>
