import { Api } from "@cp/lib";
import {
  required,
  minMaxLength,
  startsWithNonSpace,
  endsWithNonSpace,
  allowMostChars,
} from "@cp/utils/rules";
import { mergeMixins, ItemFormModal, ItemsTable } from "@cp/store/mixins";
import { Search, Filterable } from "../mixins";
import { eventbus } from "@cp/lib";

const module = "assignments";
const baseUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/assignment_groups`;
const fiverUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/`;
const marigoldUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/`;
const haystackUrl = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/`;
const mApi = new Api(marigoldUrl);
const fApi = new Api(baseUrl);

export const itemFormModal = new ItemFormModal({
  module,
  baseUrl,
  url: "/:id",
  urlTemplate: true,
  routeName: "Assignments",
  params: {
    structures: {
      addon_options: ["include_assignees", "include_escalations"],
    },
  },
  initialValue: {
    id: "",
    client_id: "",
    name: "",
    assignment_group_status_id: 1,
    "is_deletable?": true,
    archived_at: null,
    created_at: "",
    updated_at: "",
    assignment_group_status: { text: "Active", value: 1 },
    assignees: [],
    assignment_ids: [],
    employee_count: 0,
    escalation_count: 0,
    escalations: [],
    escalation_ids: [],
  },
  fields: {
    name: {
      label: "Give your assignment group a name",
      rules: [
        required,
        startsWithNonSpace,
        minMaxLength(3, 155),
        allowMostChars,
        endsWithNonSpace,
      ],
    },
    assignment_group_status_id: {
      initialValue: 1,
    },
    assignment_ids: {
      initialValue: [],
      rules: [required],
    },
    escalation_ids: {
      initialValue: [],
    },
  },
});

export const itemsTable = new ItemsTable({
  module,
  baseUrl,
  noun: "assignment",
  params: {
    structures: {
      addon_options: ["include_assignee_count", "include_escalation_count"],
    },
  },
  headers: [
    {
      text: "Assignment Group",
      align: "start",
      sortable: true,
      value: "name",
      cellClass: "clickable-cell",
    },
    {
      text: "Employee #",
      value: "employee_count",
      sortable: true,
      width: 120,
      align: "center",
    },
    {
      text: "Task #",
      value: "task_count",
      sortable: true,
      width: 120,
      align: "center",
    },
    {
      text: "Modified",
      value: "updated_at",
      sortable: true,
      width: 150,
    },
  ],
  filters: [
    "q_text",
    {
      type: "select",
      key: "general",
      label: "General",
      items: [
        {
          label: "Missing Assignees",
          value: "missing_active_assignees",
        },
      ],
    },
    {
      type: "select",
      key: "status",
      label: "Status",
      items: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      multiple: true,
      initialValue: [1],
    },
  ],
});

const employees = new Filterable({
  module,
  baseUrl: `${haystackUrl}team`,
  compoundId: true,
  name: "employees",
  filters: [
    "q_text",
    {
      label: "Region",
      type: "sub_query",
      key: "regions",
      multiple: true,
    },
    {
      label: "Position",
      type: "sub_query",
      key: "positions",
      multiple: true,
    },
  ],
});

window.$assignmentsEmployees = employees;

export default mergeMixins(itemFormModal, itemsTable, employees, {
  state: {
    employeesData: [],
    locationsList: [],
    employeeOptions: {
      filters: [],
    },
    apiErrors: {},
  },
  getters: {
    pageSize(state) {
      return state.itemsTableOptions.page.size;
    },
    pageNumber(state) {
      return state.itemsMeta.pagination.current;
    },
    pageCount(state) {
      return Math.ceil(
        state.itemsMeta.pagination.records / state.itemsTableOptions.page.size
      );
    },
  },
  actions: {
    async fetchAssignmentGroup({ state }, id) {
      if (!id) return;

      await fApi
        .authorize()
        .get(id)
        .then(({ data }) => {
          state.item = data;
        })
        .catch(console.log)
        .finally(() => {});
    },

    fetchGroups({ state }, options = {}) {
      state.loading = true;
      fApi
        .authorize()
        .get("autocomplete", Object.assign(state.itemsTableOptions, options))
        .then(({ data, meta }) => {
          state.items = data.length > 0 ? data : [];
          state.itemsMeta = meta;
        })
        .finally(() => {
          state.loading = false;
        });
    },
    editGroup({ state }, group = {}) {
      return fApi
        .authorize()
        .put(group.id, group.data)
        .catch(error => (state.apiErrors = error.response.data));
    },

    fetchLocations({ state }) {
      mApi
        .authorize()
        .get("locations")
        .then(({ data, meta }) => {
          state.locationsList = data;
        })
        .finally(() => {});
    },

    async archiveAssignment({ state, dispatch }) {
      return itemFormModal.client
        .put("/archive", { ids: [state.item.id] })
        .then(() => {
          eventbus.$emit("snackAlert", {
            message: "Assignment archived",
            color: "success",
          });
          dispatch("fetchItem", { id: state.item.id });
        });
    },

    async restoreAssignment({ state, dispatch }) {
      return itemFormModal.client
        .put("/restore", { ids: [state.item.id] })
        .then(() => {
          eventbus.$emit("snackAlert", {
            message: "Assignment restored",
            color: "success",
          });
          dispatch("fetchItem", { id: state.item.id });
        });
    },

    async duplicateAssignment({ state, dispatch }) {
      const id = state.item.id;
      return itemFormModal.client.put(`${id}/duplicate`).then(() => {
        eventbus.$emit("snackAlert", {
          message: "Assignment duplicated",
          color: "success",
        });
        dispatch("closeItemFormModal");
      });
    },

    async deleteAssignment({ state, dispatch }) {
      const id = state.item.id;
      return itemFormModal.client.delete(id).then(() => {
        eventbus.$emit("snackAlert", {
          message: "Assignment deleted",
          color: "success",
        });
        dispatch("closeItemFormModal");
      });
    },
  },
});
