import { Api } from "@cp/lib";
import { keyBy } from "@cp/utils/arrayUtils";
import {
  required,
  minMaxLength,
  startsWithNonSpace,
  endsWithNonSpace,
  allowMostChars,
  isInteger,
  min,
  max,
  requiredIf,
  ifFnRules,
} from "@cp/utils/rules";
import { modalAddon } from "@cp/store/addons";
import {
  mergeMixins,
  Mixin,
  ItemFormModal,
  ItemsTable,
} from "@cp/store/mixins";
import { Search } from "../mixins";
import { eventbus } from "@cp/lib";

const module = "tasks";
const fiverUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1`;
const apiUrl = `${fiverUrl}/tasks/`;
const fApi = new Api(apiUrl);
const baseUrl = `${fiverUrl}/tasks`;

export const taskTypes = [
  {
    label: "Onboarding",
    value: 1,
    dueRelativeTo: "start date",
    employeeFor: "new hire",
  },
  {
    label: "Offboarding",
    value: 2,
    dueRelativeTo: "last day",
    employeeFor: "departing employee",
  },
];
export const taskTypeObj = keyBy(taskTypes, "value");

const scheduled = {
  id: 1,
  icon: "mdi-calendar-edit-outline",
  title: "Scheduled",
  datePrefix: "Scheduled for",
  dateAttr: "due_by_date",
  color: "#0070a8",
  chipProps: {
    color: "#0070a8",
    class: "white--text",
  },
  alertType: "success",
};

const pending = {
  id: 2,
  icon: "mdi-checkbox-blank-outline",
  title: "Pending",
  datePrefix: "Due on",
  dateAttr: "due_by_date",
  color: "#008A09",
  chipProps: {
    color: "#008A09",
    class: "white--text",
  },
  alertType: "warning",
};

const pastDue = {
  id: 3,
  icon: "mdi-alert-box-outline",
  title: "Past Due",
  datePrefix: "Was due",
  dateAttr: "due_by_date",
  color: "#FBAF57",
  chipProps: {
    color: "#FBAF57",
    class: "grey--text text--darken-3",
  },
};

export const completed = {
  id: 4,
  icon: "mdi-checkbox-outline",
  title: "Completed",
  datePrefix: "Updated on",
  dateAttr: "updated_at",
  color: "#008A09",
  chipProps: {
    color: "#008A09",
    class: "white--text",
  },
  alertType: "success",
};

export const progressStatuses = {
  scheduled,
  pending,
  pastDue,
  completed,
};

export const progressStatusesOrder = [scheduled, pending, pastDue, completed];

export const progressStatusesById = keyBy(
  Object.values(progressStatuses),
  "id"
);

export const onTime = {
  id: 1,
  icon: "mdi-checkbox-outline",
  title: "Completed On Time",
  datePrefix: "Completed on ",
  dateAttr: "completed_at",
  color: "#008A09",
  chipProps: {
    color: "#008A09",
    class: "white--text",
  },
  alertType: "success",
};

const late = {
  id: 2,
  icon: "mdi-timer-alert-outline",
  title: "Completed Late",
  datePrefix: "Was completed late on",
  dateAttr: "completed_at",
  color: "#FBAF57",
  chipProps: {
    color: "#FBAF57",
    class: "grey--text text--darken-3",
  },
};

export const cantComplete = {
  id: 3,
  icon: "mdi-checkbox-blank-off-outline",
  title: "Can't Complete",
  datePrefix: "Updated on",
  dateAttr: "updated_at",
  color: "#8B5DCB",
  chipProps: {
    color: "#8B5DCB",
    class: "white--text",
  },
  alertType: "warning",
  verb: "Marked",
};

export const abandoned = {
  id: 4,
  icon: "mdi-alert",
  title: "Abandoned",
  datePrefix: "Updated on",
  dateAttr: "updated_at",
  color: "#E40000",
  chipProps: {
    color: "#E40000",
    class: "white--text",
  },
  verb: "Marked",
};

const canceled = {
  id: 5,
  icon: "mdi-checkbox-blank-off-outline",
  title: "Canceled",
  datePrefix: "Canceled on",
  dateAttr: "updated_at",
  color: "#999",
  chipProps: {
    color: "#999",
    class: "black--text",
  },
};

export const complianceStatuses = {
  onTime,
  late,
  cantComplete,
  abandoned,
  canceled,
};

export const complianceStatusesOrder = [
  onTime,
  late,
  cantComplete,
  abandoned,
  canceled,
];

export const complianceStatusesById = keyBy(
  Object.values(complianceStatuses),
  "id"
);

export const itemFormModal = new ItemFormModal({
  module,
  baseUrl,
  url: "/:id",
  urlTemplate: true,
  routeName: "Tasks",
  initialValue: {
    id: "",
    is_core: false,
    task_status_id: 1,
    task_type_id: 1,
    title: "",
    description: "",
    updated_at: "",
    "is_deletable?": false,
    due_type: "",
    due_day_distance: 1,
    assignment_type: "",
    assignment_group_id: "",
    alert_lead_time: 4,
    days_till_escalate: 1,
    days_till_abandon: 1,
    escalate: true,
    task_type: {
      label: "",
      value: 1,
    },
    task_status: {
      label: "",
      value: 1,
    },
    schedule: "",
    assigned_to: "",
    employees_on_task: 0,
    plans: [],
    assignment_groups: [],
    files: [],
    task_archetype_id: 2,
    tag_list: [],
  },
  fields: {
    title: {
      label: "Task Title",
      rules: [
        required,
        startsWithNonSpace,
        minMaxLength(3, 155),
        allowMostChars,
        endsWithNonSpace,
      ],
    },
    description: {
      label: "Description",
    },
    task_type_id: { initialValue: 1 },
    task_status_id: { initialValue: 1 },
    due_type: {
      initialValue: "before",
      resetOthers: "due_day_distance",
    },
    due_day_distance: {
      initialValue: 1,
      min: 1,
      rules: [
        ifFnRules(({ due_type }) => due_type != "on", [
          required,
          isInteger,
          min(1),
          max(999),
        ]),
      ],
    },
    assignment_type: { initialValue: "" /*, rules: [required] */ }, // MICHAEL!?!?!
    assignment_group_id: {
      initialValue: "",
      // rules: [ // MICHAEL?!?!!
      //   requiredIf(
      //     ({ assignment_type }) => assignment_type === "assignment_group"
      //   ),
      // ],
    },
    alert_lead_time: {
      initialValue: 4,
      min: 0,
      rules: [required, isInteger, min(0), max(999)],
    },
    days_till_abandon: {
      initialValue: 14,
      min: 1,
      rules: [required, isInteger, min(1), max(999)],
    },
    days_till_escalate: {
      initialValue: 7,
      min: 1,
      rules: [required, isInteger, min(1), max(999)],
    },
    escalate: { initialValue: true },
    tag_list: { initialValue: [] },
    task_archetype_id: { initialValue: 2 },
  },
});

// THIS IS DIRTY
// For now, mock up the needed mixin for modalAddon
// in future, we should make a `class SubFormModal{}`
// but that involves too much work for now.
export const dueSubForm = new Mixin({ module });
dueSubForm.instantiate({
  keys: {
    stateKey: "due",
    nameCaps: "Due",
  },
});
dueSubForm.add(
  modalAddon({
    modalName: "subForm",
  })
);

export const attachmentSubForm = new Mixin({ module });
attachmentSubForm.instantiate({
  keys: {
    stateKey: "attachments",
    nameCaps: "Attachments",
  },
});
attachmentSubForm.add(
  modalAddon({
    modalName: "subForm",
  })
);

export const itemsTable = new ItemsTable({
  module,
  baseUrl,
  noun: "task",
  tableOptions: {
    sort_by: "title",
  },
  headers: [
    {
      text: "",
      value: "is_core",
      width: 0,
      class: "px-0",
      cellClass: "px-0 text-no-wrap",
      sortable: false,
    },
    {
      text: "Tasks",
      value: "title",
      sortable: true,
      cellClass: "clickable-cell",
    },
    {
      text: "Tags",
      value: "tag_list",
      sortable: false,
    },
    {
      text: "Type",
      value: "task_type_id",
    },
    {
      text: "Modified",
      value: "updated_at",
      sortable: true,
      width: 150,
    },
  ],
  filters: [
    "q_text",
    {
      type: "select",
      key: "status",
      label: "Status",
      items: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      multiple: true,
      initialValue: [1],
    },
    {
      type: "radio",
      key: "task_types",
      label: "Task Type",
      items: [
        { label: "All Types", value: "" },
        { label: "Onboarding", value: 1 },
        { label: "Offboarding", value: 2 },
      ],
      initialValue: "",
    },
    {
      type: "sub_query",
      key: "plans",
      filterKey: "in_plan",
      label: "Task is in plan",
      multiple: true,
    },
    {
      type: "sub_query",
      key: "assignment_groups",
      filterKey: "assignment_groups",
      label: "Assignment Group",
      multiple: true,
    },
    {
      type: "select",
      key: "assignment_type",
      label: "Task is assigned to",
      multiple: true,
      items: [
        { label: "District Manager", value: "district_manager" },
        { label: "Regional Manager", value: "regional_manager" },
        { label: "Property Manager", value: "property_manager" },
        { label: "Direct Supervisor", value: "direct_supervisor" },
        { label: "Mentor", value: "mentor" },
        { label: "Assignment Group", value: "assignment_group" },
        { label: "Employee", value: "employee" },
      ],
    },
    {
      type: "sub_query",
      key: "assignment_groups",
      filterKey: "assignment_group",
      label: "Assignment Group",
      multiple: true,
    },
  ],
});

window.$tasksTable = itemsTable;

const assignments = new Search({
  module,
  baseUrl: `${fiverUrl}/assignment_groups/autocomplete`,
  name: "assignments",
  params: { filters: { status: [1] } },
  mapOptions: ({ id: value, name: text } = {}) => ({ value, text }),
});

const tags = new Search({
  module,
  baseUrl: `${fiverUrl}/tasks/tags`,
  name: "tags",
  mapOptions: ({ id: value, name: text } = {}) => ({ value, text }),
  loadMore: true,
});

export default mergeMixins(
  itemFormModal,
  itemsTable,
  assignments,
  tags,
  dueSubForm,
  attachmentSubForm,
  {
    actions: {
      async addAttatchment({ state }, payload = {}) {
        // TODO - use itemFormModal.client instead
        const requestConfig = {
          method: "POST",
          url: `${payload.id}/add_attachment`,
          responseType: "json",
          data: payload.file,
        };
        if (typeof payload.onUploadProgress === "function") {
          requestConfig.onUploadProgress = payload.onUploadProgress;
        }
        await fApi.authorize().request(requestConfig);
      },

      async deleteAttatchment({ state }, payload = {}) {
        // TODO - use itemFormModal.client instead
        await fApi
          .authorize()
          .put(`${payload.task_id}/remove_attachment/${payload.attachment_id}`)
          .then(response => {})
          .catch(error => console.log)
          .finally(() => {});
      },

      async archiveTask({ state, dispatch }) {
        return itemFormModal.client
          .put("/archive", { ids: [state.item.id] })
          .then(() => {
            eventbus.$emit("snackAlert", {
              message: "Task archived",
              color: "success",
            });
            dispatch("fetchItem", { id: state.item.id });
          });
      },

      async restoreTask({ state, dispatch }) {
        return itemFormModal.client
          .put("/restore", { ids: [state.item.id] })
          .then(() => {
            eventbus.$emit("snackAlert", {
              message: "Task restored",
              color: "success",
            });
            dispatch("fetchItem", { id: state.item.id });
          });
      },

      async duplicateTask({ state, dispatch }) {
        const id = state.item.id;
        return itemFormModal.client.put(`${id}/duplicate`).then(() => {
          eventbus.$emit("snackAlert", {
            message: "Task duplicated",
            color: "success",
          });
          dispatch("closeItemFormModal");
        });
      },

      async deleteTask({ state, dispatch }) {
        const id = state.item.id;
        return itemFormModal.client.delete(id).then(() => {
          eventbus.$emit("snackAlert", {
            message: "Task deleted",
            color: "success",
          });
          dispatch("closeItemFormModal");
        });
      },
    },
  }
);
