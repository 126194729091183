import { ItemFormModal } from "@cp/store/mixins";
import { requiredIf } from "@cp/utils/rules";
import { parse } from "@cp/utils/dateUtils";

import { adminActions } from "./taskAssignments";

const module = "taskStatusForm";

export const taskStatusForm = new ItemFormModal({
  module,
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/user_tasks`,
  url: "/:id",
  urlTemplate: true,
  initialValue: {
    completed_at: "",
    admin_actions: [],
    task: {},
    on_behalf_of: {},
    assignees: [],
    plans: [],
    files: [],
    due_by_date: "",
    abandon_date: "",
    escalate_date: "",
    user_task_progress_status_id: 1,
    user_task_compliance_status_id: 0,
    notes: [],
  },
  fields: {
    admin_action: {
      initialValue: "add_note",
      resetOthers: ["completed_by", "completed_at"],
    },
    completed_by: {
      rules: [requiredIf(({ admin_action }) => admin_action === "complete")],
    },
    completed_at: {
      initialValue: parse().format("YYYY-MM-DD"),
      rules: [requiredIf(({ admin_action }) => admin_action === "complete")],
    },
    note: {
      rules: [requiredIf(({ admin_action }) => admin_action != "complete")],
    },
  },
});
taskStatusForm.instantiate({ adminActions });

export default taskStatusForm.toVuex;
