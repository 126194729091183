import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v("Total: "+_vm._s(_vm.meta.pagination.records))]},proxy:true},{key:"rightOfTitle",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.openModal()}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add ")],1)]},proxy:true},{key:"right",fn:function(){return [_c('cp-filters-menu',{staticClass:"search--center",attrs:{"module":_vm.table}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.constants.ASSIGNMENT_GROUPS)+" ")]),_c(VCard,{staticClass:"card-listing"},[_c(VDataTable,_vm._g(_vm._b({staticClass:"clickable-rows",on:{"click:row":function (item) { return _vm.openModal({ id: item.id }); }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('no-data-screen',{attrs:{"text":_vm.noDataText,"noun":_vm.noun},on:{"click":function($event){return _vm.openModal()}}})]},proxy:true},{key:"item.updated_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.toMDY(value))+" ")]}},{key:"item.employee_count",fn:function(ref){
var item = ref.item;
return [(!item.employee_count)?_c(VBtn,{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.openModal({ id: item.id })}}},[_c(VIcon,{staticClass:"mr-5px",attrs:{"small":""}},[_vm._v(" mdi-account-multiple-plus ")]),_vm._v(" Add Employees ")],1):_c('span',[_vm._v(_vm._s(item.employee_count))])]}},{key:"item.alert",fn:function(ref){
var item = ref.item;
return [_c('cp-explain',{attrs:{"close-on-content-click":false,"max-width":"550","offset-x":"","root-slot":""}},[_c('assignment-detail-card',{attrs:{"item":item}})],1)]}}])},'v-data-table',_vm.tableProps,false),_vm.tableListeners)),(!_vm.noData)?_c(VPagination,_vm._g(_vm._b({staticClass:"mt-2 text-center"},'v-pagination',_vm.paginationProps,false),_vm.paginationListeners)):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }