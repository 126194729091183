<template>
  <div>
    <div v-for="(auditsOnDay, index) in auditsByDate" :key="`audit-${index}`">
      <v-sheet color="#fff" class="pa-1 px-2 mb-2" rounded light>
        <span style="color: #666666" class="text-subtitle-1 font-weight-bold">
          {{ auditsOnDay.date }}
        </span>
        <audits
          v-for="(audit, ai) in auditsOnDay.audits"
          :audit="audit"
          :key="ai"
        />
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { itemHistoryModal } from "@/store/modules/taskAssignments";

export default {
  computed: {
    ...mapState(itemHistoryModal.mp, ["item"]),
    auditsByDate() {
      return this.item.reduce((r, audit) => {
        const date = audit.audit_date;
        const found = r.find(x => x.date === date);
        if (found) found.audits.push(audit);
        else
          r.push({
            date,
            audits: [audit],
          });
        return r;
      }, []);
    },
  },
};
</script>
