import List from "./List.vue";
import Detail from "./Detail.vue";
export default [
  {
    path: "/mentors",
    name: "mentors",
    component: List,
    meta: {
      title: "Mentors",
    },
  },
  {
    path: "/mentors/:id",
    name: "mentorDetail",
    component: Detail,
    meta: {
      title: "Mentor",
    },
  },
];
